import React, { useEffect, useState } from 'react';
import { Button, Col, Form, FormControl, InputGroup, Row } from 'react-bootstrap';
import axios from 'axios';
import { host, url } from '../../../config/common';
import Loader from '../../utils/loader';
import CustomPagination from './pagination/customPagination';
import FboUpdateCard from '../../utils/fboUpdateCard';
import { useNavigate } from 'react-router-dom';

const FboUpdates = () => {
    const [searchInput, setSearchInput] = useState("");
    const [fboUpdatesData, setFboUpdatesData] = useState({
        list: [],
        loading: false,
        currentPage: "1",
        totalPages: 1,
        totalRecords: 1,
    });
    const [fboUpdatesParams, setFboUpdatesParams] = useState({
        page: 1,
        limit: 21,
    });
    const [showloader, setShowloader] = useState(false);
    let navigate = useNavigate();

    const fetchFboUpdates = async () => {
        try {
            setShowloader(true)
            let fetchedEventData = await axios({
                method: 'get',
                headers: { 'x-access-token': JSON.parse(localStorage.getItem('user'))?.token ? JSON.parse(localStorage.getItem('user')).token : "" },
                url: `${host}${url.getShareAnUpdate}?search=${searchInput}&page=${fboUpdatesParams.page}&limit=${fboUpdatesParams.limit}&eventName=fbo_updates`
            });
            if (fetchedEventData?.status == 401) {
                localStorage.removeItem("user")
                return navigate("/")
            }
            if (fetchedEventData) {
                setShowloader(false);
                setFboUpdatesData((v) => ({
                    ...v,
                    list: fetchedEventData?.data?.data?.records,
                    loading: false,
                    currentPage: fetchedEventData?.data?.data?.currentPage,
                    totalPages: fetchedEventData?.data?.data?.totalPages,
                    totalRecords: fetchedEventData?.data?.data?.totalRecords,
                }));
            }
        } catch (error) {
            console.log({ error });
            if (error?.response?.status == 401) {
                localStorage.removeItem("user")
                return navigate("/")
            }
        }
    }

    const searchFobUpdates = async (e) => {
        try {
            setFboUpdatesParams((v) => ({ ...v, page: 1 }));
            fetchFboUpdates();
        } catch (error) {
            console.log({ error });
        }
    }

    const handlePageChange = (e, page) => {
        setFboUpdatesParams((v) => ({ ...v, page: page }));
    };

    const paginationProps = {
        currentPage: fboUpdatesData?.currentPage,
        totalPages: fboUpdatesData?.totalPages,
        totalRecords: fboUpdatesData?.totalRecords,
        handlePageChange,
    };

    useEffect(() => {
        fetchFboUpdates();
    }, [fboUpdatesParams]);

    return (
        <div style={{ padding: "0px 20px" }}>
            <Row>
                <Col>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <h3>FBO Update Requests</h3>
                    </div>
                </Col>
            </Row>
            <Row>
                <div>
                    <Row>
                        <Col xs="7">
                            <Form onSubmit={(e) => { e.preventDefault(); searchFobUpdates() }}>
                                <InputGroup style={{ width: "500px", float: "left", marginBottom: "1%" }}>
                                    <FormControl
                                        placeholder="Search by FBO name"
                                        onChange={(e) => setSearchInput(e.target.value)}
                                        value={searchInput}
                                    />
                                    <Button variant="outline-primary" type='submit'>
                                        Search
                                    </Button>
                                </InputGroup>
                            </Form>
                        </Col>
                    </Row>
                    <Loader show={showloader} />
                    <Row style={{ height: "82vh", overflow: "scroll " }}>
                        <Col>
                            {fboUpdatesData?.list?.length > 0
                                ?
                                <>
                                    <Row style={{ marginTop: "20px" }}>
                                        {
                                            fboUpdatesData?.list?.map((item) =>
                                                <Col sm="4" key={item._id}>
                                                    <FboUpdateCard fboUpdatesData={item} refreshData={() => fetchFboUpdates()} />
                                                </Col>
                                            )
                                        }
                                    </Row>
                                    <CustomPagination props={{ paginationProps }} />
                                </>
                                :
                                <Row style={{ marginTop: "20px" }}>No Records Found</Row>
                            }

                        </Col>
                    </Row>
                </div>
            </Row>
        </div>
    );
}

export default FboUpdates;
