import React, { useState } from 'react';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import LocalAirportIcon from '@mui/icons-material/LocalAirport';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import LanguageIcon from '@mui/icons-material/Language';
import InterestsIcon from '@mui/icons-material/Interests';
import { Button, Card, Col, Modal, Row } from 'react-bootstrap';
import axios from 'axios';
import { host, url } from '../../../config/common';
import { useAlert } from 'react-alert';
import SocialContentCreateModal from './socialContentCreateModal';
import { Approval } from '@mui/icons-material';

const SocialContentManageCard = ({ socialContentData, refreshData, airportList, ...props }) => {
  const [showEdit, setShowEdit] = useState(false);
  const [showDeleteModal, setshowDeleteModal] = useState(false);

  const alert = useAlert()

  const handleEdit = () => {
    setShowEdit(true)
  }

  const handleClose = () => {
    setShowEdit(false)
  }

  const deleteMedia = async (id) => {
    try {
      let deletedEvent = await axios({
        method: 'delete',
        headers: { 'x-access-token': JSON.parse(localStorage.getItem('user'))?.token ? JSON.parse(localStorage.getItem('user')).token : "" },
        url: `${host}${url.deleteSocialMediaContent}${id}`
      });
      if (deletedEvent) {
        setshowDeleteModal(false)
        refreshData()
      }
    } catch (error) {
      console.log({ error });
    }
  }

  const handleWebsiteClick = (url) => {
    if (url.startsWith('http://') || url.startsWith('https://')) {
      window.open(url, '_blank');
    } else {
      window.open(`http://${url}`, '_blank');
    }
  }

  return (
    <div>
      <SocialContentCreateModal show={showEdit} handleClose={handleClose} socialContentData={socialContentData} refreshData={() => refreshData()} airportList={airportList} />
      <Modal show={showDeleteModal} onHide={() => setshowDeleteModal(false)} backdrop="static">
        <Modal.Body>Are you sure you want to delete the media content?</Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => setshowDeleteModal(false)}>
            No
          </Button>
          <Button variant="outline-danger" onClick={() => deleteMedia(socialContentData._id)}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Card style={{ marginBottom: "10px", minHeight: "330px" }}>
        <Card.Body style={{ textAlign: "initial", display:"flex", justifyContent: "space-between", flexDirection: "column" }}>
          <div>
          <p style={{ fontWeight: "300" }}><LocalAirportIcon fontSize='10px' />  <b>Airport ID :</b> {socialContentData?.airportID} - {socialContentData.airportName}</p>
          <p style={{ fontWeight: "300" }}><Approval fontSize='10px' /><b>FBO Name :</b> {socialContentData.fboName}</p>
          <p style={{ fontWeight: "300" }}><InterestsIcon fontSize='10px' />  <b>Media Platform :</b> {socialContentData?.socialMediaPlatform}</p>
          <p style={{ fontWeight: "300" }}><PhoneAndroidIcon fontSize='10px' />  <b>Post Title :</b> {socialContentData?.titleOfPost}</p>
          <p style={{ fontWeight: "300" }}><LanguageIcon fontSize='10px' />  <b>Link To Post :</b> {socialContentData?.linkToPost && <span style={{color:"#0d6efd", cursor: "pointer"}} onClick={() => handleWebsiteClick(socialContentData?.linkToPost)}>{socialContentData?.linkToPost}</span>}</p>
          </div>
          <Row>
            <Col>
              <div style={{ display: "flex", justifyContent: "end" }}>
                <span style={{ fontWeight: "300", color: "#006ba3", cursor: "pointer", marginRight: "10px" }} onClick={() => handleEdit()}><DriveFileRenameOutlineIcon fontSize='10px' /> Edit</span>
                <span style={{ fontWeight: "300", color: "red", cursor: "pointer" }} onClick={() => setshowDeleteModal(true)}><DeleteOutlineIcon fontSize='9px' /> Delete</span>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
}

export default SocialContentManageCard;
