import React, { useState } from 'react';
import DateRange from '@mui/icons-material/DateRange';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import LocalAirportIcon from '@mui/icons-material/LocalAirport';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import PersonIcon from '@mui/icons-material/Person';
import SwipeRightIcon from '@mui/icons-material/SwipeRight';
import { Button, Card, Col, Modal, Row } from 'react-bootstrap';
import moment from 'moment';
import axios from 'axios';
import { host, url } from '../../config/common';
import { useAlert } from 'react-alert';
import CoolEventUpdateModal from './coolEventUpdateModal';
import { Approval } from '@mui/icons-material';

const CoolEventCard = ({ coolEventsData, refreshData, airportList, ...props }) => {
  const [showEdit, setShowEdit] = useState(false);
  const [showDeleteModal, setshowDeleteModal] = useState(false);
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [showFullDescription, setFullDescription] = useState(false);
  const alert = useAlert();

  const description = showFullDescription
    ? coolEventsData?.about
    : coolEventsData?.about?.slice(0, 30);

  const showFullDescriptionHandler = () => {
    setFullDescription(!showFullDescription);
  };

  const handleEdit = () => {
    setShowEdit(true)
  }

  const handleClose = () => {
    setShowEdit(false)
  }

  const acceptRejectRequest = async (id, status) => {
    try {
      let eventRequest = await axios({
        method: 'patch',
        headers: { 'x-access-token': JSON.parse(localStorage.getItem('user'))?.token ? JSON.parse(localStorage.getItem('user')).token : "" },
        url: `${host}${url.changeStatusShareAnUpdate}`,
        data: {
          _id: id,
          status: status
        }
      });
      if (eventRequest) {
        alert.show(`Request ${status == 1 ? "accepted" : "rejected"}`, {type: 'success'})
        setshowDeleteModal(false);
        setShowAcceptModal(false);
        refreshData()
      }
    } catch (error) {
      console.log({ error });
    }
  }

  return (
    <div>
      <CoolEventUpdateModal show={showEdit} handleClose={handleClose} coolEventsData={coolEventsData} airportList={airportList} refreshData={() => refreshData()}/>
      <Modal show={showDeleteModal} onHide={() => setshowDeleteModal(false)} backdrop="static">
        <Modal.Body>Are you sure you want to reject the cool event request?</Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => setshowDeleteModal(false)}>
            No
          </Button>
          <Button variant="outline-danger" onClick={() => acceptRejectRequest(coolEventsData._id, 2)}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showAcceptModal} onHide={() => setShowAcceptModal(false)} backdrop="static">
        <Modal.Body>Are you sure you want to accept the cool event request?</Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => setShowAcceptModal(false)}>
            No
          </Button>
          <Button variant="outline-danger" onClick={() => acceptRejectRequest(coolEventsData._id, 1)}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Card style={{ flexDirection: "initial", marginBottom: "10px" }}>
        <Card.Body style={{ textAlign: "initial" }}>
          <p style={{ fontWeight: "300" }}><LocalAirportIcon fontSize='10px' />  <b>Airport ID :</b> {coolEventsData?.airportID}</p>
          <p style={{ fontWeight: "300" }}><Approval fontSize='10px' />  <b>FBO :</b> {coolEventsData?.fboName}</p>
          <p style={{ fontWeight: "300" }}><LocalActivityIcon fontSize='10px' />  <b>Event Name :</b> {coolEventsData?.name}</p>
          <p style={{ fontWeight: "300" }}><PriceChangeIcon fontSize='10px' />  <b>Event Price :</b> {coolEventsData?.price?.amount}</p>
          <p style={{ fontWeight: "300" }}><DateRange fontSize='10px' />  <b>Event Start Date :</b> {moment(coolEventsData.startDate).format("Do MMM YYYY")} - {moment(coolEventsData.startDate).format("h:mm A")} </p>
          <p style={{ fontWeight: "300" }}><DateRange fontSize='10px' />  <b>Event End Date :</b> {moment(coolEventsData.endDate).format("Do MMM YYYY")} - {moment(coolEventsData.endDate).format("h:mm A")} </p>
          <p style={{ fontWeight: "300" }}><HourglassBottomIcon fontSize='10px' />  <b>Event Location :</b> {coolEventsData?.eventLocation}</p>
          <p style={{ fontWeight: "300" }}><ContactMailIcon fontSize='10px' />  <b>Event Details :</b> {description} {coolEventsData?.about?.length > 30 && <span onClick={showFullDescriptionHandler}>
            <b>Read {showFullDescription ? "Less" : "More..."}</b></span>}</p>
          <p style={{ fontWeight: "300" }}><PersonIcon fontSize='10px' />  <b>Requested By :</b> {coolEventsData?.requestedBy}</p>
          <p style={{ fontWeight: "300" }}><DateRange fontSize='10px' />  <b>Requested Date :</b> {moment(coolEventsData?.createdAt).format("Do MMM YYYY")}</p>
          <Row>
            <Col>
              <div style={{ display: "flex", justifyContent: "end" }}>
                {coolEventsData?.status === 0 ?
                  <div>
                    <span style={{ fontWeight: "300", color: "#006ba3", cursor: "pointer", marginRight: "10px" }} onClick={() => handleEdit()}><SwipeRightIcon fontSize='9px' /> Accept</span>
                    <span style={{ fontWeight: "300", color: "red", cursor: "pointer" }} onClick={() => setshowDeleteModal(true)}><DeleteOutlineIcon fontSize='9px' /> Reject</span>
                  </div>
                  :
                  coolEventsData?.status === 1 ? <span style={{color: "green"}}>Accepted</span> : <span style={{color: "red"}}>Rejected</span>
                }

              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
}

export default CoolEventCard;
