import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Form, Offcanvas, Row } from 'react-bootstrap';
import FormData from 'form-data'
import { useAlert } from 'react-alert'
import { host, url } from '../../config/common';
import axios from 'axios';

const FboUpdateModal = ({ show, handleClose, fboUpdatesData, refreshData, ...props }) => {
  const [disableButtons, setDisableButtons] = useState(false);
  const [modalEventData, setModalEventData] = useState({
    airportID: fboUpdatesData?.airportID,
    fboName: fboUpdatesData?.fboName,
    fboAsri: "",
    fboContact: "",
    fboWebsite: "",
    _id: fboUpdatesData?._id
  });

  const [validated, setValidated] = useState(false);
  const alert = useAlert()

  const createUnlistedFbo = async (e) => {
    e.preventDefault();
    try {
      const form = e.currentTarget;
      console.log("validate", form.checkValidity());
      if (form.checkValidity() === false) {
        e.stopPropagation();
        setValidated(true);
      } else {
        setValidated(true);
        setDisableButtons(true);
        let updateEvent = await axios({
          method: 'post',
          headers: { 'x-access-token': JSON.parse(localStorage.getItem('user'))?.token ? JSON.parse(localStorage.getItem('user')).token : "" },
          url: `${host}${url.approveUnlistedFboUpdate}`,
          data: {
            _id: modalEventData?._id,
            airportID: modalEventData?.airportID,
            name: modalEventData?.fboName,
            ASRI: modalEventData?.fboAsri,
            contactNumber: modalEventData?.fboContact,
            website: modalEventData?.fboWebsite,
          }
        });
        if (updateEvent) {
          alert.show("New FBO created", { type: 'success' });
          setModalEventData({
            airportID: fboUpdatesData?.airportID,
            fboName: fboUpdatesData?.fboName,
            fboAsri: "",
            fboContact: "",
            fboWebsite: "",
            _id: fboUpdatesData?._id
          });
          refreshData();
          setDisableButtons(false);
          handleClose();
        }
      }
    } catch (error) {
      console.log({ error });
    }
  }

  const closeAction = () => {
    if (modalEventData._id) {
      handleClose()
      return
    }
    setModalEventData({
      airportID: fboUpdatesData?.airportID,
      fboName: fboUpdatesData?.fboName,
      fboAsri: "",
      fboContact: "",
      fboWebsite: "",
      _id: fboUpdatesData?._id
    })
    setDisableButtons(false)
    handleClose()
  }

  useEffect(() => {
    setModalEventData({
      airportID: fboUpdatesData?.airportID,
      fboName: fboUpdatesData?.fboName,
      fboAsri: "",
      fboContact: "",
      fboWebsite: "",
      _id: fboUpdatesData?._id
    })
  }, [fboUpdatesData]);

  return (
    <div>
      <Offcanvas backdrop="static" style={{ width: "50%" }} placement="end" show={show} onHide={closeAction} >
        <Offcanvas.Body>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Create Unlisted FBO</Offcanvas.Title>
          </Offcanvas.Header>
          <Form noValidate validated={validated} onSubmit={createUnlistedFbo}>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Airport ID <span style={{ color: "red" }}>*</span></Form.Label>
                  <Form.Control type='text' value={modalEventData.airportID} readOnly />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>FBO Name <span style={{ color: "red" }}>*</span></Form.Label>
                  <Form.Control required type='text' placeholder="FBO Name" onChange={(e) => setModalEventData({ ...modalEventData, fboName: e.target.value })} value={modalEventData?.fboName} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>FBO Contact</Form.Label>
                  <Form.Control type='text' placeholder="FBO Contact"
                    onChange={(e) => setModalEventData({ ...modalEventData, fboContact: e.target.value })}
                    value={modalEventData.fboContact}
                    pattern="^\d{10}$"
                    isInvalid={
                      validated && modalEventData.fboContact != "" && !/^\d{10}$/.test(modalEventData.fboContact)
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid 10-digit phone number.
                  </Form.Control.Feedback>
                </Form.Group>

              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>FBO</Form.Label>
                  <Form.Control type='text' value={modalEventData?.fboName} readOnly />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>FBO ASRI</Form.Label>
                  <Form.Control type='text' placeholder="FBO ASRI" onChange={(e) => setModalEventData({ ...modalEventData, fboAsri: e.target.value })} value={modalEventData?.fboAsri} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>FBO Website</Form.Label>
                  <Form.Control type='text' placeholder="FBO Website" onChange={(e) => setModalEventData({ ...modalEventData, fboWebsite: e.target.value })} value={modalEventData?.fboWebsite} />
                </Form.Group>
              </Col>
            </Row>
            <div>
              <Button disabled={disableButtons} variant="outline-secondary" style={{ marginRight: "5px" }} onClick={closeAction}>
                Cancel
              </Button>
              <Button disabled={disableButtons} variant="outline-primary" type='submit'>
                Save
              </Button>
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default FboUpdateModal;
