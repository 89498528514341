import React, { useEffect, useMemo, useState } from 'react';
import { Button, Col, Form, InputGroup, ListGroup, Offcanvas, Row, Spinner } from 'react-bootstrap';
import FormData from 'form-data'
import { useAlert } from 'react-alert'
import { host, url } from '../../../config/common';
import axios from 'axios';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import debounce from 'lodash/debounce';

const OnsiteFoodCreateModal = ({ show, handleClose, foodData, refreshData, airportList, ...props }) => {
  const [disableButtons, setDisableButtons] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedFBO, setSelectedFBO] = useState("");
  const [isDisplayResults, setIsDisplayResults] = useState({ display: "none" });

  const [searchAirportInput, setSearchAirportInput] = useState("");
  const [selectedAirport, setSelectedAirport] = useState("");
  const [isDisplayAirportResults, setIsDisplayAirportResults] = useState({ display: "none" });
  const [searchTerm, setSearchTerm] = useState('');

  const initalData = {
    airportID: "",
    fboId: "",
    fboName: "",
    restaurantName: "",
    restaurantAddress: "",
    aboutRestaurant: "",
    restaurantPhone: "",
    restaurantWebsite: "",
    restaurantOperationHours: "",
    restaurantImagesUrls: []
  };
  const [modalEventData, setModalEventData] = useState(foodData?._id ? { ...foodData } : initalData);

  const [validated, setValidated] = useState(false);
  const alert = useAlert();
  const user = localStorage.getItem('user');

  const updateFood = async (e) => {
    e.preventDefault();
    try {
      const form = e.currentTarget;
      console.log("validate", form.checkValidity());
      if (form.checkValidity() === false) {
        e.stopPropagation();
        setValidated(true);
      } else {
        if (modalEventData?.airportID == "") {
          alert.show("Please select an airport.", {type: 'error'})
          return;
        }
        setValidated(true);
        setDisableButtons(true);
        const formData = new FormData();
        if (modalEventData?._id) {
          formData.append("_id", modalEventData?._id);
        }
        formData.append("fboId", modalEventData?.fboId);
        formData.append("fboName", modalEventData?.fboName);
        formData.append("airportID", modalEventData?.airportID);
        formData.append("restaurantName", modalEventData?.restaurantName);
        formData.append("restaurantPhone", modalEventData?.restaurantPhone);
        formData.append("restaurantWebsite", modalEventData?.restaurantWebsite);
        formData.append("restaurantOperationHours", modalEventData?.restaurantOperationHours);
        formData.append("restaurantAddress", modalEventData?.restaurantAddress);
        formData.append("aboutRestaurant", modalEventData?.aboutRestaurant);
        if (user) {
          formData.append("uid", JSON.parse(user)._id);
        }

        uploadedFiles.forEach((image) => {
          formData.append("restaurantImages", image?.file);
        });
        let updateOnsiteFood = await axios({
          method: 'post',
          headers: { 'x-access-token': JSON.parse(localStorage.getItem('user'))?.token ? JSON.parse(localStorage.getItem('user')).token : "" },
          url: `${host}${url.createEditOnsiteFood}`,
          data: formData
        });
        if (updateOnsiteFood) {
          setUploadedFiles([]);
          setModalEventData(initalData);
          refreshData();
          setDisableButtons(false);
          handleClose();
          setSelectedAirport("");
          setSelectedFBO("");
          alert.show("Onsite food updated", { type: 'success' });
        }
        setValidated(false);
      }
    } catch (error) {
      setDisableButtons(false);
      console.log({ error });
    }
  }

  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files)
    handleUploadFiles(chosenFiles);
  }

  const handleUploadFiles = (files) => {
    const allowedFormats = ["image/jpeg", "image/png", "image/jpg"];
    const filteredFiles = files.filter((file) => allowedFormats.includes(file.type));

    const updatedImages = filteredFiles.map((file) => ({
      file,
      preview: URL.createObjectURL(file),
    }));

    setUploadedFiles((prevImages) => {
      const combinedImages = [...prevImages, ...updatedImages];
      return combinedImages;
    });
  }

  const handleDeleteImage = async (image, id) => {
    //handle delete img from s3
    try {
      let removeImage = await axios({
        method: 'delete',
        headers: { 'x-access-token': JSON.parse(localStorage.getItem('user'))?.token ? JSON.parse(localStorage.getItem('user')).token : "" },
        url: `${host}${url.removeImage}`,
        data: {
          _id: id,
          imageUrl: image
        }
      });
      if (removeImage) {
        alert.show("Image removed", { type: 'success' });
        refreshData();
        setDisableButtons(false);
      }
    } catch (error) {
      console.log({ error });
    }
  }

  const searchFBO = async (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      let searchedFBOs = await axios({
        method: 'get',
        headers: { 'x-access-token': JSON.parse(localStorage.getItem('user'))?.token ? JSON.parse(localStorage.getItem('user')).token : "" },
        url: `${host}${url.searchFBO}?key=${searchInput}&limit=10&skip=0&`
      });
      if (searchedFBOs) {
        setSearchResults(searchedFBOs.data.data);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log({ error });
      if (error.response.status == 404) {
        alert.show("No FBO found", { type: 'error' })
      }
    }
  }

  const searchAirport = async (e) => {
    e.preventDefault();
    debouncedSetSearchTerm(searchAirportInput);
  }

  const debouncedSetSearchTerm = useMemo(
    () => debounce((value) => setSearchTerm(value), 300),
    [searchAirportInput]
  );

  const filteredAirport = useMemo(() => {
    if (!searchTerm) return [];
    return airportList.filter(airport =>
      airport.airportID.toLowerCase().includes(searchTerm.toLowerCase()) || airport.airportName.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [searchTerm]);

  const setAirport = (item) => {
    setSearchAirportInput("")
    setSearchTerm("")
    setSelectedAirport(item)
    setModalEventData({ ...modalEventData, airportID: item.airportID, fboName: "", fboId: ""})
    setSelectedFBO("");
  }

  const setFBO = (item) => {
    setSearchInput("")
    setSelectedFBO(item)
    setModalEventData({ ...modalEventData, fboId: item._id, fboName: item.name, airportID: item.airportID })
    setSearchResults([])
    let port = airportList.filter(airport =>
      airport.airportID == item.airportID
    );
    setSelectedAirport(port[0]);
  }

  const closeAction = () => {
    if (modalEventData._id) {
      setModalEventData({ ...foodData })
      handleClose()
      return
    } else {
      setUploadedFiles([]);
      setSearchResults([]);
      setSearchInput("");
      setSelectedFBO("");
      setValidated(false);
      setSearchAirportInput("")
      setSearchTerm("")
      setSelectedAirport("")
    }
    setModalEventData(initalData);
    setDisableButtons(false)
    handleClose()
  }

  useEffect(() => {
    setModalEventData(foodData?._id ? { ...foodData } : initalData);
    if (foodData?._id) {
      let port = airportList.filter(item => item.airportID == foodData.airportID)
      setSelectedAirport(port[0]);
    }
  }, [foodData]);

  return (
    <div>
      <Offcanvas backdrop="static" style={{ width: "50%" }} placement="end" show={show} onHide={closeAction} >
        <Offcanvas.Body>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>{modalEventData?._id ? "Edit" : "Add"} Onsite Food</Offcanvas.Title>
          </Offcanvas.Header>
          <Form noValidate validated={validated} onSubmit={updateFood}>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <div className='upload-files-container'>
                    <div className='drag-file-area'>
                      <span className='upload-icon'>
                        <FileUploadIcon />
                        <input type='file' className='default-file-input' multiple onChange={handleFileEvent} />
                      </span>
                      <label className='label'>
                        <span className='browse-files-text'>Upload Restaurant Images <span style={{ color: "red" }}>*</span></span>
                      </label>
                    </div>
                  </div>

                </Form.Group>
                <div className="uploaded-files-list mb-3" style={{ display: "flex", flexWrap: "wrap", gap: "30px" }}>
                  {modalEventData?.restaurantImagesUrls && modalEventData?.restaurantImagesUrls.map((image, index) => (
                    <div key={index} className='upload_image_show_column' style={{ position: "relative" }}>
                      <img
                        style={{
                          height: "190px",
                          width: "179px",
                        }}
                        src={image}
                        alt={`Preview ${index + 1}`}
                      />
                      <span
                        className='remove-icon'
                        style={{ position: "absolute", cursor: "pointer" }}
                        onClick={() => {
                          handleDeleteImage(image, modalEventData?._id);
                        }}>
                        &#x2715;
                      </span>
                    </div>
                  ))}
                  {uploadedFiles.map((image, index) => (
                    <div key={index} className='upload_image_show_column' style={{ position: "relative" }}>
                      <img
                        style={{
                          height: "190px",
                          width: "179px",
                        }}
                        src={image.preview}
                        alt={`Preview ${index + 1}`}
                      />
                      <span
                        className='remove-icon'
                        style={{ position: "absolute", cursor: "pointer" }}
                        onClick={() => {
                          setUploadedFiles(uploadedFiles.filter((_, i) => i !== index));
                        }}>
                        &#x2715;
                      </span>
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div style={{ position: "relative" }}>
                  <Form.Group style={modalEventData._id ? { display: "" } : {}}>
                    <Form.Label>Airport</Form.Label>
                    <InputGroup>
                      <Form.Control
                        placeholder="Search Airport"
                        value={searchAirportInput}
                        onChange={(e) => setSearchAirportInput(e.target.value)}
                        // onChange={handleSearchChange}
                        onFocus={() => { setIsDisplayAirportResults({ display: "block" }) }}
                      />
                      <Button variant="outline-primary" onClick={searchAirport}>
                        Search
                      </Button>
                    </InputGroup>
                  </Form.Group>
                  <div style={filteredAirport?.length > 0 ? { display: "", width: "100%", position: "absolute", zIndex: "999", maxHeight: "500px", overflowY: "scroll" } : { width: "100%", position: "absolute", zIndex: "999"}}>
                    <ListGroup style={{ textAlign: "initial", boxShadow: "4px 5px 9px #808080" }}>
                      {
                        filteredAirport?.map((item) =>
                          <ListGroup.Item action key={item.airportID} onClick={(e) => { e.preventDefault(); setAirport(item) }}>
                            {item.airportID} : {item.airportName}
                          </ListGroup.Item>)
                      }
                    </ListGroup>
                  </div>
                  <div className='mt-2 mb-2'>Airport <span style={{ color: "red" }}>* </span>:
                    <span style={{ color: '#006ba3' }}>{
                      modalEventData._id ? ` ${modalEventData.airportID} : ${selectedAirport?.airportName}`
                        : selectedAirport ? ` ${selectedAirport.airportID} : ${selectedAirport.airportName}` : ""}
                    </span>
                  </div>
                </div>

                <div style={{ position: "relative" }}>
                  <Form.Group style={modalEventData._id ? { display: "" } : {}}>
                    <Form.Label>Select FBO</Form.Label>
                    <InputGroup>
                      <Form.Control
                        placeholder="Search FBO"
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                        onFocus={() => { setIsDisplayResults({ display: "block" }) }}
                      />
                      <Button variant="outline-primary" onClick={searchFBO}>
                        {isLoading && <Spinner as="span" animation="border" size="sm" />}
                        {isLoading ? "" : "Search"}
                      </Button>
                    </InputGroup>
                  </Form.Group>
                  <div style={modalEventData._id || !searchInput ? { display: "", width: "100%", position: "absolute", zIndex: "1" } : { width: "100%", position: "absolute", zIndex: "1" }}>
                    <ListGroup style={{ textAlign: "initial", boxShadow: "4px 5px 9px #808080" }}>
                      {
                        searchResults?.map((item) =>
                          <ListGroup.Item action key={item._id} onClick={(e) => { e.preventDefault(); setFBO(item) }}>
                            {item.airportID} : {item.name}
                          </ListGroup.Item>)
                      }
                    </ListGroup>
                  </div>
                  <div className='mt-2 mb-2'>FBO Name :
                    <span style={{ color: '#006ba3' }}>{
                      modalEventData._id ? ` ${modalEventData.airportID} : ${modalEventData.fboName}`
                        : selectedFBO ? ` ${selectedFBO.airportID} : ${selectedFBO.name}` : ""}
                    </span>
                  </div>
                </div>
                <Form.Group className="mb-3">
                  <Form.Label>Restaurant Phone <span style={{ color: "red" }}>*</span></Form.Label>
                  <Form.Control required type='text' placeholder="Restaurant Phone"
                    onChange={(e) => setModalEventData({ ...modalEventData, restaurantPhone: e.target.value })}
                    value={modalEventData.restaurantPhone}
                    pattern="^\d{10}$"
                    isInvalid={
                      validated && !/^\d{10}$/.test(modalEventData.restaurantPhone)
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid 10-digit phone number.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Restaurant Operation Hours <span style={{ color: "red" }}>*</span></Form.Label>
                  <Form.Control required type='text' placeholder="Restaurant Operation Hours" onChange={(e) => setModalEventData({ ...modalEventData, restaurantOperationHours: e.target.value })} value={modalEventData.restaurantOperationHours} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" style={{ paddingBottom: "24px" }}>
                  <Form.Label>Restaurant Name <span style={{ color: "red" }}>*</span></Form.Label>
                  <Form.Control required type='text' placeholder="Restaurant Name" onChange={(e) => setModalEventData({ ...modalEventData, restaurantName: e.target.value })} value={modalEventData.restaurantName} />
                </Form.Group>
                <Form.Group className="mb-3" style={{ paddingBottom: "24px" }}>
                  <Form.Label>Restaurant Website <span style={{ color: "red" }}>*</span></Form.Label>
                  <Form.Control required type='text' placeholder="Restaurant Website" onChange={(e) => setModalEventData({ ...modalEventData, restaurantWebsite: e.target.value })} value={modalEventData.restaurantWebsite} />
                </Form.Group>
                <Form.Group className="mb-3 mt-3">
                  <Form.Label>Restaurant Address <span style={{ color: "red" }}>*</span></Form.Label>
                  <Form.Control required type='text' placeholder="Restaurant Address" onChange={(e) => setModalEventData({ ...modalEventData, restaurantAddress: e.target.value })} value={modalEventData.restaurantAddress} />
                </Form.Group>
                <Form.Group className="mb-3 mt-3">
                  <Form.Label>About Restaurant <span style={{ color: "red" }}>*</span></Form.Label>
                  <Form.Control required as="textarea" rows={3} placeholder="About Restaurant" onChange={(e) => setModalEventData({ ...modalEventData, aboutRestaurant: e.target.value })} value={modalEventData.aboutRestaurant} />
                </Form.Group>
              </Col>
            </Row>
            <div>
              <Button disabled={disableButtons} variant="outline-secondary" style={{ marginRight: "5px" }} onClick={closeAction}>
                Cancel
              </Button>
              <Button disabled={disableButtons} variant="outline-primary" type='submit'>
                Save
              </Button>
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default OnsiteFoodCreateModal;
