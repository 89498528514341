import React, { useState, useEffect } from "react";
import { Offcanvas, Button, Table, Form, ButtonGroup, InputGroup, FormControl, Dropdown, Image, Spinner } from "react-bootstrap"
import VisibilityIcon from '@mui/icons-material/Visibility';
import axios from "axios";
import { useAlert } from "react-alert";
import defaultImage from "./../asset/dummy-image.jpg"
import { host, url } from "../../config/common";
import Loader from "./loader";
import TokenExpireHandler from "./tokenExpireHandler";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import ControlPointIcon from '@mui/icons-material/ControlPoint';

export default function AirportInfoPopup({ name, airportData, reRenderfn, navigate, ...props }) {
  const alert = useAlert()
  const [show, setShow] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [dropdownValue, setDropdownValue] = useState("Facility type")
  const [stateAirportData, airportOperations] = useState(airportData);
  const [file, setFile] = useState(airportData?.image ? airportData.image : defaultImage);
  const [uploadingImageLoader, setUploadingImageLoader] = useState(false);
  const customClass = {
    OffcanvasCustomWidth: {
      width: '80%'
    }
  }
  useEffect(() => {
    airportOperations(airportData)
    if (airportData.facilityType) {
      setDropdownValue(airportData.facilityType)
    }
  }, [airportData])
  const handleClose = () => { setShow(false); airportOperations(airportData) };
  const handleShow = () => { setShow(true) }

  const updateFacilityType = (value) => {
    setDropdownValue(value)
    let stateFetched = { ...stateAirportData }
    stateFetched["facilityType"] = value
    airportOperations(stateFetched)
    debugger
  }

  const updateAirportData = async () => {
    try {
      // setShowLoader(true)
      alert.show("Updating...", { type: 'warning' })
      await axios({
        method: 'patch',
        headers: { 'x-access-token': JSON.parse(localStorage.getItem('user'))?.token ? JSON.parse(localStorage.getItem('user')).token : "" },
        url: `${host}${url.updateIndividualAirportData}`,
        data: { stateAirportData }
      });
      // setShowLoader(false)
      alert.show("Updated.", { type: 'success' })
      reRenderfn()
      handleClose()
    } catch (e) {
      console.log(e)
      alert.show("Something went wrong.", { type: 'error' })
      TokenExpireHandler(navigate, e)
    }
  }

  let addNewFbo = () => {
    let newFbo = {
      name: "",
      ASRI: "",
      airportID: "",
      contactNumber: "",
      website: ""
    }
    let newFboArr = stateAirportData.fbo
    newFboArr.push(newFbo)
    airportOperations({ ...stateAirportData, fbo: newFboArr });
  }

  let saveOrUpdateFbo = async (fboElement) => {
    try {
      // setShowLoader(true)
      alert.show("Updating...", { type: 'warning' })
      if (fboElement._id) {
        fboElement["airportID"] = stateAirportData.airportID
        await axios({
          method: 'patch',
          headers: { 'x-access-token': JSON.parse(localStorage.getItem('user'))?.token ? JSON.parse(localStorage.getItem('user')).token : "" },
          url: `${host}${url.updateFboData}`,
          data: fboElement
        });
        // setShowLoader(false)
        alert.show("Updated.", { type: 'success' })
        reRenderfn()
        return
      }
      fboElement["airportID"] = stateAirportData.airportID
      // setShowLoader(true)
      await axios({
        method: 'post',
        headers: { 'x-access-token': JSON.parse(localStorage.getItem('user'))?.token ? JSON.parse(localStorage.getItem('user')).token : "" },
        url: `${host}${url.saveFboData}`,
        data: fboElement
      });
      // setShowLoader(false)
      alert.show("Saved.", { type: 'success' })
      reRenderfn()
    } catch (e) {
      console.log(e)
      alert.show("Something went wrong.", { type: 'error' })
      TokenExpireHandler(navigate, e)
    }
  }

  let deleteFbo = async (fboElement) => {
    try {
      if (fboElement._id) {
        setShowLoader(true)
        fboElement["airportID"] = stateAirportData.airportID
        await axios({
          method: 'delete',
          headers: { 'x-access-token': JSON.parse(localStorage.getItem('user'))?.token ? JSON.parse(localStorage.getItem('user')).token : "" },
          url: `${host}${url.deleteFbo}${fboElement._id}`,
          data: fboElement
        });
        setShowLoader(false)
        alert.show("Deleted.", { type: 'success' })
        reRenderfn()
        return
      }
      alert.show("Removed.", { type: 'success' })
      reRenderfn()
    } catch (e) {
      console.log(e);
      alert.show("Something went wrong.", { type: 'error' })
      TokenExpireHandler(navigate, e)
    }
  }

  const uploadImage = async (event) => {
    try {
      console.log({ event });
      // get the selected file from the input
      const file = event.target.files[0];
      setUploadingImageLoader(true)
      // create a new FormData object and append the file to it
      const formData = new FormData();
      formData.append("image", file);
      formData.append("airportID", airportData.airportID);
      let uploadedFile = await axios({
        method: 'post',
        headers: { 'x-access-token': JSON.parse(localStorage.getItem('user'))?.token ? JSON.parse(localStorage.getItem('user')).token : "", "Content-Type": "multipart/form-data" },
        url: `${host}${url.uploadAirportImage}`,
        data: formData
      });
      if (uploadedFile) {
        airportOperations({ ...airportData, image: uploadedFile.data.imageUrl });
        setFile(uploadedFile.data.imageUrl);
        setUploadingImageLoader(false);
      }
    } catch (error) {
      console.log({ error });
    }
  }

  let renderFBO = () => {
    return stateAirportData.fbo.map((item, index) =>
      <Table key={index} striped bordered hover size="sm">
        <thead>
          <tr>
            <th>FBO Name</th>
            <th>FBO ASRI</th>
            <th>FBO Contact</th>
            <th>FBO Website</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Form.Group controlId="formGridCity">
                <Form.Control
                  value={item.name}
                  onChange={e => {
                    let newFboArr = stateAirportData.fbo
                    newFboArr[index].name = e.target.value
                    airportOperations({ ...stateAirportData, fbo: newFboArr });
                  }}
                />
              </Form.Group>
            </td>
            <td>
              <Form.Group controlId="formGridCity">
                <Form.Control
                  value={item.ASRI}
                  onChange={e => {
                    let newFboArr = stateAirportData.fbo
                    newFboArr[index].ASRI = e.target.value
                    airportOperations({ ...stateAirportData, fbo: newFboArr });
                  }}
                />
              </Form.Group>
            </td>
            <td>
              <Form.Group controlId="formGridCity">
                <Form.Control
                  value={item.contactNumber}
                  onChange={e => {
                    let newFboArr = stateAirportData.fbo
                    newFboArr[index].contactNumber = e.target.value
                    airportOperations({ ...stateAirportData, fbo: newFboArr });
                  }}
                />
              </Form.Group>
            </td>
            <td>
              <InputGroup className="mb-3">
                <Form.Control
                  value={item.website}
                  onChange={e => {
                    let newFboArr = stateAirportData.fbo
                    newFboArr[index].website = e.target.value
                    airportOperations({ ...stateAirportData, fbo: newFboArr });
                  }}
                />
                <Button disabled={item.website == "" ? true : false} variant="outline-secondary" onClick={() => { window.open(item.website, '_blank') }}>
                  Visit
                </Button>
              </InputGroup>
            </td>
            <td>
              <ButtonGroup aria-label="Basic example">
                <Button variant="outline-primary" onClick={() => saveOrUpdateFbo(item)}>Save</Button>
                <Button variant="outline-danger" onClick={() => deleteFbo(item)}>Delete</Button>
              </ButtonGroup>
            </td>
          </tr>
        </tbody>
      </Table>
    )
  }

  return (
    <>
      <Loader show={showLoader} />
      <Button variant="outline-primary" className="me-2" onClick={handleShow}>
        <VisibilityIcon />
      </Button>
      <Offcanvas style={customClass.OffcanvasCustomWidth} show={show} onHide={handleClose} backdrop="static" {...props}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>FBOProps Details of: {airportData.airportName}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div style={{ marginBottom: "10px" }}>
            <div>
              <Image width={"200px"} height={"200px"} src={file} rounded />
              {
                uploadingImageLoader ? <Spinner animation="border" size='sm' style={{ color: "#006ba3", marginLeft: "10px", marginRight: "10px" }} /> :
                  <label style={{ fontWeight: "300", color: "#006ba3", cursor: "pointer", marginRight: "10px", marginLeft: "10px" }}>
                    {
                      stateAirportData?.image ?
                        <DriveFileRenameOutlineIcon fontSize='10px' /> :
                        <ControlPointIcon fontSize='10px' />
                    }
                    <input onChange={uploadImage} style={{ display: "none" }} type="file" />
                    {stateAirportData?.image ? " Edit" : " Add"}
                  </label>
              }
            </div>
          </div>
          <Form onSubmit={(e) => e.preventDefault()}>
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>Airport Id</th>
                  <th>Airport Name</th>
                  <th>Airport State</th>
                  <th>Airport City</th>
                  <th>Airport details</th>
                  <th>Facility type</th>
                  <th>Latitude</th>
                  <th>Longitude</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{airportData.airportID}</td>
                  <td>
                    <Form.Group controlId="formGridCity">
                      <Form.Control
                        value={stateAirportData.airportName}
                        onChange={e => {
                          airportOperations({ ...stateAirportData, airportName: e.target.value });
                        }}
                      />
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group controlId="formGridCity">
                      <Form.Control
                        value={stateAirportData.state}
                        onChange={e => {
                          airportOperations({ ...stateAirportData, state: e.target.value });
                        }}
                      />
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group controlId="formGridCity">
                      <Form.Control
                        value={stateAirportData.city}
                        onChange={e => {
                          airportOperations({ ...stateAirportData, city: e.target.value });
                        }}
                      />
                    </Form.Group>
                  </td>
                  <td>
                    <InputGroup>
                      <FormControl
                        value={stateAirportData.airportDetails}
                        onChange={e => {
                          airportOperations({ ...stateAirportData, airportDetails: e.target.value });
                        }}
                      />
                      <Button variant="outline-secondary" onClick={() => { window.open(stateAirportData.airportDetails, '_blank') }}>
                        Visit
                      </Button>
                    </InputGroup>
                  </td>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
                        {dropdownValue}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => updateFacilityType("AIRPORT")}>AIRPORT</Dropdown.Item>
                        <Dropdown.Item onClick={() => updateFacilityType("HELIPORT")}>HELIPORT</Dropdown.Item>
                        <Dropdown.Item onClick={() => updateFacilityType("SEAPLANE BASE")}>SEAPLANE BASE</Dropdown.Item>
                        <Dropdown.Item onClick={() => updateFacilityType("GLIDERPORT")}>GLIDERPORT</Dropdown.Item>
                        <Dropdown.Item onClick={() => updateFacilityType("BALLOONPORT")}>BALLOONPORT</Dropdown.Item>
                        <Dropdown.Item onClick={() => updateFacilityType("ULTRALIGHT")}>ULTRALIGHT</Dropdown.Item>
                        <Dropdown.Item onClick={() => updateFacilityType("VERTIPORT")}>VERTIPORT</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                  <td>
                    <InputGroup>
                      <FormControl
                        value={stateAirportData.location?.latitude}
                        onChange={e => {
                          airportOperations({ ...stateAirportData, location: { latitude: parseFloat(e.target.value), longitude: parseFloat(stateAirportData.location?.longitude) } });
                        }}
                      />
                    </InputGroup>
                  </td>
                  <td>
                    <InputGroup>
                      <FormControl
                        value={stateAirportData.location?.longitude}
                        onChange={e => {
                          airportOperations({ ...stateAirportData, location: { latitude: parseFloat(stateAirportData.location?.latitude), longitude: parseFloat(e.target.value) } });
                        }}
                      />
                    </InputGroup>
                  </td>
                  <td>
                    <Button variant="outline-primary" onClick={() => updateAirportData()}>Update</Button>
                  </td>
                </tr>
              </tbody>
            </Table>
            {
              renderFBO()
            }
            <Button variant="outline-info" className="me-2" onClick={addNewFbo} >Add new FBO</Button>
            <Button variant="danger" onClick={handleClose}>Close</Button>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}