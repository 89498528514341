import React from 'react'
import { Table, Card, InputGroup, FormControl, ButtonToolbar, ButtonGroup, Button, Form } from 'react-bootstrap'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import axios from 'axios'

import { host, url } from './../../../config/common'
import ReviewModPopup from './../../utils/reviewModPopup'
import Loader from './../../utils/loader'
import StarRatings from 'react-star-ratings/build/star-ratings';
import TokenExpireHandler from '../../utils/tokenExpireHandler';
export default class UserList extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      allReviews: null,
      showloader: false,
      limit: 20,
      skip: 0,
      searchValue: "",
      paginationRender: 1,
      isMoreDataAvailable: false
    }
  }
  async fetchAllReviews() {
    try {
      this.setState({showloader: true})
      let allReviews = await axios({  
        method: 'get',  
        headers: {'x-access-token': JSON.parse(localStorage.getItem('user'))?.token ? JSON.parse(localStorage.getItem('user')).token : ""},
        url: `${host}${url.getAllReviews}`,
        params: {
          limit: this.state.limit,
          skip: this.state.skip
        }
      });
      if(allReviews.status == 401){
        localStorage.removeItem("user")
        return this.props.navigate("/")
      }
      if(!allReviews.data.data.length) {
        this.setState({skip: this.state.skip - 20, paginationRender: this.state.paginationRender - 1})
        this.fetchAllReviews()
      }
      if (allReviews) {
        this.setState({showloader: false})
        this.setState({allReviews: allReviews.data.data})
        if (!allReviews.data.isMoreDataAvailable.length) {
          this.setState({isMoreDataAvailable: true})
        }else {
          this.setState({isMoreDataAvailable: false})
        }
      }
    } catch (e) {
      console.log('error', e)
      TokenExpireHandler(this.props.navigate, e)
    }
  } 

  componentDidMount() {
    this.fetchAllReviews()
  }

  async search() {
    try {
      let key = this.state.searchValue
      this.setState({paginationRender: 1})
      if (key) {
        let searchData = await axios({  
          method: 'get',  
          headers: {'x-access-token': JSON.parse(localStorage.getItem('user'))?.token ? JSON.parse(localStorage.getItem('user')).token : ""},
          url: `${host}${url.searchReviews}${key}`
        });
        if (searchData) {
          this.setState({skip: 0})
          this.setState({allReviews: searchData.data})
          return
        }
        this.props.alert.show("No data found.", {type: 'info'})
        return
      }
      this.fetchAllReviews()
    } catch (e) {
      console.log('error', e)
      if (e.response.status == 404) {
        this.props.alert.show("No data found.", {type: 'info'})
      }
      TokenExpireHandler(this.props.navigate, e)
    }
  }

  setSearchValue (e) {
    if (e.target.value == "") {
      this.fetchAllReviews()
    }
    this.setState({searchValue: e.target.value})
  }

  paginationAction(clickedAction) {
    this.setState({showloader: true})
    if (clickedAction == "previous") {
      this.setState({paginationRender: this.state.paginationRender - 1})
      return this.setState({skip: this.state.skip - 20}, () => this.fetchAllReviews())
    }
    if (clickedAction == "next") {
      this.setState({paginationRender: this.state.paginationRender + 1})
      this.setState({skip: this.state.skip + 20}, () => this.fetchAllReviews())
    }
  }

  paginationCount(){
    let paginations = []
    for (let index = 1; index <= this.state.paginationRender; index++) {
      paginations.push(<Button variant='light' onClick={() => {this.setState({skip: index * 20}, () => this.fetchAllReviews())}}>{index}</Button>)
    }
    return [paginations[paginations.length - 5], paginations[paginations.length - 4],paginations[paginations.length - 3], paginations[paginations.length - 2], paginations[paginations.length - 1]]
  }

  
  render() {
    if (!this.state.allReviews){
      return <Loader show={this.state.showloader}/>
    }
    let renderReviews =  this.state.allReviews.map((review, index) => 
        <tr key={index}>
          <td>{index + 1 + this.state.skip}</td>
          <td>{review.userName ? review.userName : "-"}</td>
          <td style={{width: "530px", height: "50px"}}>
            <div style={{width: "530px", height: "50px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>
              {review.review ? review.review : "-"}
            </div>
          </td>
          <td>
            <StarRatings
              starRatedColor='rgb(13,110,253,1)'
              rating={review.stars}
              starDimension="15px"
              numberOfStars={5}
              name='rating'
            />
          </td>
          <td>
            <ReviewModPopup reviewDataProp={review} placement="end" reRenderFn={() => this.fetchAllReviews()} />
          </td>
        </tr>
      )
    return (
      <>
        <Loader show={this.state.showloader}/>
        <Card>
          <Card.Body>
            <Form onSubmit={(e) =>{e.preventDefault(); this.search()}}>
              <InputGroup style={{width: "500px", float: "left", marginBottom: "1%"}}>
                <FormControl
                  placeholder="Search users and reviews."
                  onChange={(e) => this.setSearchValue(e)}
                />
                <Button variant="outline-primary" type='submit'>
                  Search
                </Button>
              </InputGroup>
            </Form>
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>Sr. No.</th>
                  <th>User</th>
                  <th>Review</th>
                  <th>Ratings</th>
                  <th>Edit</th>
                </tr>
              </thead>

              <tbody style={this.state.showloader ? {display: "none" }: {display: "contents"}}>
                {
                  renderReviews
                }
              </tbody>
            </Table>
            <ButtonToolbar aria-label="Toolbar with button groups" style={{justifyContent: 'center'}}>
              <ButtonGroup className="me-2" aria-label="First group">
                <Button disabled={this.state.skip === 0 } onClick={() => this.paginationAction("previous")}><ArrowBackIosNewIcon /> Previous page</Button>
                {this.paginationCount()}
                <Button disabled={this.state.allReviews.length !== 20 || this.state.isMoreDataAvailable } onClick={() => this.paginationAction("next")}>Next page <ArrowForwardIosIcon/></Button>
              </ButtonGroup>
            </ButtonToolbar>
          </Card.Body>
        </Card>
      </>
    )
  }
}
