import React, { useCallback, useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';

import Sidebar from './sidebar'
import Pannel from './pannel'
import image from './../asset/MapSidebar.png'
import './style.css'
import { host, url } from '../../config/common';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { fetchAirports } from '../../store/slices';
import Loader from '../utils/loader';

export default function Body() {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [hoverStyle, sethoverStyle] = useState({display: "none"})
  const [loading, setLoading] = useState(true);

  let mouseOverEvent = () => {
    sethoverStyle({display: "unset"})
  }
  
  let mouseOffEvent = () => {
    sethoverStyle({display: "none"})
  }

  const fetchAirportData = useCallback(async () => {
    let allAirportData = await axios({
        method: 'get',
        headers: { 'x-access-token': JSON.parse(localStorage.getItem('user'))?.token ? JSON.parse(localStorage.getItem('user')).token : "" },
        url: `${host}${url.getAirportNameList}?isBeta=true`,
        params: { fromAdmin: true, isBeta: true }
    });
    if (allAirportData) {
      dispatch(fetchAirports(allAirportData.data));
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchAirportData();
  }, [])

  return (
    <>
      <Loader show={loading} />
      <Container fluid style={{height: '100vh',margin: "0px", padding: "0px"}}>
        <Row style={{height: '100%', margin: "0px", padding: "0px"}}>
          <Col className="sidebarAnimate" style={{ height: "-webkit-fill-available", position: "fixed", zIndex: "1", overflowY:"scroll"}} onMouseOver={mouseOverEvent} onMouseOut={mouseOffEvent}>
            <div>
              <Sidebar navigate={navigate} hoverStyle={hoverStyle}/>
            </div>
          </Col>
          <Col lg="12">
            <Pannel></Pannel>
          </Col>
        </Row>
      </Container>
    </>
  )
}
