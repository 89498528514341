import React from 'react'
import {Table, Button, Card, InputGroup, FormControl, ButtonToolbar, ButtonGroup, Form, Badge} from 'react-bootstrap'
import DeleteIcon from '@mui/icons-material/Delete';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import axios from 'axios'

import { url, host} from './../../../config/common'
import AirportInfoPopup from './../../utils/airportInfoPopup'
import AirportDeletionModal from '../../utils/deletionModal';
import AirportDataModificationModal from './../../utils/airportDataMod'
import AirportAddModal from './../../utils/airportAddModal'
import Loader from '../../utils/loader';
import TokenExpireHandler from './../../utils/tokenExpireHandler'
export default class AirportData extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      addAirportModalshow: false,
      allAirportData: null,
      deleteModalShow: false,
      settingModalShow: false,
      airportDataForDeletion: null,
      fboData: null,
      showloader: true,
      limit: 20,
      skip: 0,
      searchValue: "",
      isSearched: false,
      paginationRender: 1
    }
  }

  async fetchAllAirportData() {
    try {
      if(!this.state.isSearched){
        this.setState({showloader: true})
      }
      let allAirportData = await axios({  
        method: 'get',  
        headers: {'x-access-token': JSON.parse(localStorage.getItem('user')).token},
        url: `${host}${url.getAllAirportData}/${this.state.limit}And${this.state.skip}`,
        params: {fromAdmin: true}
      });
      if(allAirportData.status == 401){
        localStorage.removeItem("user")
        return this.props.navigate("/")
      }
      if (allAirportData) {
        this.setState({showloader: false})
        this.setState({allAirportData: allAirportData.data.result})
        this.setState({isSearched: false})
        localStorage.setItem("airportData",JSON.stringify(allAirportData.data.result))
        return 
      }
      this.setState({showloader: false})
      this.props.alert.show("No data avilable.", {type: 'warning'})
    } catch (e) {
      console.log('error',{e})
      TokenExpireHandler(this.props.navigate, e)
    }
  }

  async search() {
    try {
      let key = this.state.searchValue
      this.setState({paginationRender: 1})
      if (key) {
        this.setState({showloader: true})
        let searchData = await axios({  
          method: 'get',
          headers: {'x-access-token': JSON.parse(localStorage.getItem('user')).token},
          url: `${host}${url.searchAirport}${key}`,
          params: {
            fromAdmin: true,
            limit: this.state.limit,
            skip: this.state.skip
          }
        });
        if(searchData.status == 401){
          localStorage.removeItem("user")
          return this.props.navigate("/")
        }
        if (searchData) {
          this.setState({allAirportData: searchData.data.airportData})
          this.setState({showloader: false})
          return
        }
        this.setState({showloader: false})
        this.props.alert.show("No airport/FBO found.", {type: 'info'})
        return
      }
      this.setState({isSearched: true}, () => {
        this.setState({allAirportData: JSON.parse(localStorage.getItem("airportData"))})
      })
    } catch (e) {
      this.setState({showloader: false})
      this.props.alert.show("No airport/FBO found.", {type: 'info'})
      console.log('error', e)
    }
  }

  async deleteAirportData(data) {
    try {
      this.setState({showloader: true})
      let deletedAirportData = await axios({  
        method: 'delete',
        headers: {'x-access-token': JSON.parse(localStorage.getItem('user')).token},
        url: `${host}${url.deleteAirportData}/${data.airportID}`
      });
      if (deletedAirportData) {
        this.setState({showloader: false})
        this.props.alert.show("Deleted.", {type: 'success'})
        this.setState({deleteModalShow: false})
        this.fetchAllAirportData()
      }
    } catch (e) {
      console.log('error', e)
      TokenExpireHandler(this.props.navigate, e)
    }
  }

  componentDidMount() {
    this.fetchAllAirportData()
  }

  paginationAction(clickedAction) {
    this.setState({showloader: true})
    if (clickedAction == "previous") {
      this.setState({paginationRender: this.state.paginationRender - 1})
      if (this.state.searchValue) {
        return this.setState({skip: this.state.skip - 20}, () => this.search())
      }
      this.setState({skip: this.state.skip - 20}, () => this.fetchAllAirportData())
    }
    if (clickedAction == "next") {
      this.setState({paginationRender: this.state.paginationRender + 1})
      if (this.state.searchValue) {
        return this.setState({skip: this.state.skip + 20}, () => this.search())
      }
      this.setState({skip: this.state.skip + 20}, () => this.fetchAllAirportData())
    }
  }

  setSearchValue (e) {
    if (e.target.value == "") {
      this.setState({isSearched: true}, () => {
        this.setState({allAirportData: JSON.parse(localStorage.getItem("airportData")), skip: 0, paginationRender: 1})
      })
    }
    this.setState({searchValue: e.target.value.replace("/", " ")})
  }

  paginationCount(){
    let paginations = []
    for (let index = 1; index <= this.state.paginationRender; index++) {
      paginations.push(<Button variant='light' onClick={() => {this.setState({skip: index * 20}, () => this.fetchAllAirportData())}}>{index}</Button>)
    }
    
    return [paginations[paginations.length - 5], paginations[paginations.length - 4],paginations[paginations.length - 3], paginations[paginations.length - 2], paginations[paginations.length - 1]]
  }

  render() {
    if (!this.state.allAirportData){
      return <Loader show={this.state.showloader}/>
    }
    let airportData =  this.state.allAirportData.map((airport) => 
      <tr key={airport.airportID}>
        <td>{airport.airportID}</td>
        <td>{airport.state}</td>
        <td>{airport.city}</td>
        <td>
          {airport.foundFBO? <Badge style={{float: "right"}} pill bg="primary">FBO</Badge> : null}
          {airport.airportName}
        </td>
        <td>
          {airport.location ? airport.location.latitude : "-"}/{airport.location ? airport.location.longitude: "-"}
        </td>
        <td>
          <AirportInfoPopup
            name="editore"
            airportData={airport}
            reRenderfn={() => this.fetchAllAirportData()}
            placement="end"
          />
          <Button variant="outline-danger" onClick={() => this.setState({deleteModalShow: true, airportDataForDeletion: airport})}>
            <DeleteIcon />
          </Button>
        </td>
      </tr>
    )
    return (
      <>
        <Loader show={this.state.showloader}/>
        <Card>
          <AirportDataModificationModal
            show={this.state.settingModalShow}
            onHide={() => this.setState({settingModalShow: false})}
          />
          <AirportDeletionModal
            deletionfunction={() => {this.deleteAirportData(this.state.airportDataForDeletion)}}
            airport={this.state.airportDataForDeletion}
            show={this.state.deleteModalShow}
            onHide={() => {this.setState({deleteModalShow: false})}}
          />
          <AirportAddModal 
            show={this.state.addAirportModalshow}
            onHide={() => this.setState({addAirportModalshow: false})}
            reRenderfn={() => this.fetchAllAirportData()}
          />
          <Card.Body>
            <Form onSubmit={(e) =>{e.preventDefault(); this.search()}}>
              <InputGroup style={{width: "500px", float: "left"}}>
                <FormControl
                  placeholder="Search Airport and FBO"
                  onChange={(e) => this.setSearchValue(e)}
                />
                <Button variant="outline-primary" type='submit'>
                  Search
                </Button>
              </InputGroup>
            </Form>
            <Button style={{ float: 'right', marginBottom: "1%", marginLeft: "1%"}} variant="outline-secondary" onClick={() => this.setState({settingModalShow: true})}>
              <NoteAddIcon />
            </Button>
            <Button style={{ float: 'right', marginBottom: "1%"}} variant="outline-primary" onClick={() => this.setState({addAirportModalshow: true})}>
              <AddCircleOutlineIcon />
            </Button>

            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>Airport Id</th>
                  <th>State</th>
                  <th>City</th>
                  <th>Airport Name</th>
                  <th>Lattitude/Longitude</th>
                  <th>Edit</th>
                </tr>
              </thead>
              <tbody>
                {
                  airportData
                }
              </tbody>
            </Table>
            <ButtonToolbar aria-label="Toolbar with button groups" style={{justifyContent: 'center'}}>
              <ButtonGroup className="me-2" aria-label="First group">
                <Button disabled={this.state.skip === 0 } onClick={() => this.paginationAction("previous")}><ArrowBackIosNewIcon /> Previous page</Button>
                {this.paginationCount()}
                <Button disabled={this.state.allAirportData.length < 20 } onClick={() => this.paginationAction("next")}>Next page <ArrowForwardIosIcon/></Button>
              </ButtonGroup>
            </ButtonToolbar>
          </Card.Body>
        </Card>
      </>
    )
  }
}
