import React, { useEffect, useMemo, useState } from 'react';
import { Alert, Button, Col, Form, Image, InputGroup, ListGroup, Modal, Offcanvas, Row, Spinner } from 'react-bootstrap';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import Datetime from 'react-datetime';
import FormData from 'form-data'
import { useAlert } from 'react-alert'


import defaultImage from "./../asset/dummy-image.jpg"
import moment from 'moment';
import { host, url } from '../../config/common';
import axios from 'axios';
import debounce from 'lodash/debounce';

const EventModal = ({ show, handleClose, eventData, refreshData, airportList, ...props }) => {
  const [file, setFile] = useState(eventData?.image ? eventData.image : defaultImage);
  const [disableButtons, setDisableButtons] = useState(false);
  const [modalEventData, setModalEventData] = useState(eventData?._id ? { ...eventData } : {
    fboId: "",
    fboName: "",
    airportName: "",
    startDate: new Date(),
    endDate: new Date(),
    price: {
      amount: "",
      currency: ""
    },
    image: "",
    airportID: "",
    eventLocation: ""
  });

  const [searchResults, setSearchResults] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [isDisplayResults, setIsDisplayResults] = useState({ display: "none" });
  const [selectedFBO, setSelectedFBO] = useState("");
  const [validated, setValidated] = useState(false);
  const [uploadingImageLoader, setUploadingImageLoader] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [searchAirportInput, setSearchAirportInput] = useState("");
  const [selectedAirport, setSelectedAirport] = useState("");
  const [isDisplayAirportResults, setIsDisplayAirportResults] = useState({ display: "none" });
  const [searchTerm, setSearchTerm] = useState('');

  const alert = useAlert()

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
  };

  const dateValidator = (date) => {
    let startDateValidator = moment(moment(modalEventData.startDate).format())
    let endDateValidator = moment(moment(date ? date : modalEventData.endDate).format())
    if (!endDateValidator.isAfter(startDateValidator)) {
      alert.show("Please select a valid end date", { type: "error", })
      return true
    }
    setModalEventData({ ...modalEventData, endDate: date })
    return false
  }

  const uploadImage = async (event) => {
    try {
      console.log({ event });
      // get the selected file from the input
      const file = event.target.files[0];
      setDisableButtons(true)
      setUploadingImageLoader(true)
      // create a new FormData object and append the file to it
      const formData = new FormData();
      formData.append("image", file);
      let uploadedFile = await axios({
        method: 'post',
        headers: { 'x-access-token': JSON.parse(localStorage.getItem('user'))?.token ? JSON.parse(localStorage.getItem('user')).token : "", "Content-Type": "multipart/form-data" },
        url: `${host}${url.uploadImage}`,
        data: formData
      });
      if (uploadedFile) {
        setModalEventData({ ...modalEventData, image: uploadedFile.data.imageUrl })
        setFile(uploadedFile.data.imageUrl)
        setDisableButtons(false)
        setUploadingImageLoader(false)
      }
    } catch (error) {
      console.log({ error });
    }
  }

  const createEvent = async (e) => {
    try {
      e.preventDefault()
      if (!modalEventData.airportID || modalEventData?.airportID == "") {
        alert.show("Please select an airport.", { type: 'error' })
        return;
      }
      if (dateValidator()) return
      setDisableButtons(true)
      let savedEvent = await axios({
        method: 'post',
        headers: { 'x-access-token': JSON.parse(localStorage.getItem('user'))?.token ? JSON.parse(localStorage.getItem('user')).token : "" },
        url: `${host}${url.creatEventByAdmin}`,
        data: modalEventData
      });
      if (savedEvent) {
        alert.show("Event saved", { type: 'success' })
        refreshData()
        setModalEventData({
          fboId: "",
          fboName: "",
          airportName: "",
          startDate: new Date(),
          endDate: new Date(),
          price: {
            amount: "",
            currency: ""
          },
          image: "",
          airportID: ""
        })
        setSelectedFBO("")
        setDisableButtons(false)
        setFile(defaultImage)
        handleClose();
      }
    } catch (error) {
      console.log({ error });
    }
  }
  const handleDelete = () => {
    setFile(defaultImage)
    setModalEventData({ ...modalEventData, image: "" })
  }

  const dateValidity = (current) => {
    let yesterday = moment().subtract(1, 'day');
    return current.isAfter(yesterday);
  };

  const dateValidityOfEndDate = (current) => {
    let yesterday = moment().subtract(1, 'day');
    return current.isAfter(yesterday);
  };

  const searchFBO = async (e) => {
    try {
      e.preventDefault()
      setIsLoading(true);
      let searchedFBOs = await axios({
        method: 'get',
        headers: { 'x-access-token': JSON.parse(localStorage.getItem('user'))?.token ? JSON.parse(localStorage.getItem('user')).token : "" },
        url: `${host}${url.searchFBO}?key=${searchInput}&limit=10&skip=0&`
      });
      if (searchedFBOs) {
        setSearchResults(searchedFBOs.data.data)
      }
      setIsLoading(false);
    } catch (error) {
      console.log({ error });
      setIsLoading(false);
      if (error.response.status == 404) {
        alert.show("No FBO found", { type: 'error' })
      }
    }
  }

  const setStartDate = (date) => {
    let currentDate = moment(new Date()).format()
    if (!date.isAfter(currentDate)) {
      setModalEventData({ ...modalEventData, startDate: currentDate, endDate: currentDate })
      alert.show("Please select a valid start date", { type: "error", })
      return true
    }
    setModalEventData({ ...modalEventData, startDate: date, endDate: date })
    return false
  }

  const searchAirport = async (e) => {
    e.preventDefault();
    debouncedSetSearchTerm(searchAirportInput);
  }

  const debouncedSetSearchTerm = useMemo(
    () => debounce((value) => setSearchTerm(value), 300),
    [searchAirportInput]
  );

  const filteredAirport = useMemo(() => {
    if (!searchTerm) return [];
    return airportList.filter(airport =>
      airport.airportID.toLowerCase().includes(searchTerm.toLowerCase()) || airport.airportName.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [searchTerm]);

  const setAirport = (item) => {
    setSearchAirportInput("")
    setSearchTerm("")
    setSelectedAirport(item)
    setModalEventData({ ...modalEventData, airportID: item.airportID, fboName: "", fboId: "" })
    setSelectedFBO("");
  }

  const setFBO = (item) => {
    setSearchInput("")
    setSelectedFBO(item)
    setModalEventData({ ...modalEventData, airportID: item?.airportID, fboId: item._id, fboName: item.name })
    setSearchResults([])
    let port = airportList.filter(airport =>
      airport.airportID == item.airportID
    );
    setSelectedAirport(port[0]);
  }

  const closeAction = () => {
    if (modalEventData._id) {
      setModalEventData({ ...eventData })
      handleClose()
      return
    }
    setModalEventData({
      fboId: "",
      fboName: "",
      airportName: "",
      startDate: new Date(),
      endDate: new Date(),
      price: {
        amount: "",
        currency: ""
      },
      image: "",
      airportID: ""
    })
    setSelectedFBO("")
    setDisableButtons(false)
    setFile(defaultImage)
    handleClose()
    setSearchAirportInput("")
    setSearchTerm("")
    setSelectedAirport("")
  }
  useEffect(() => {
    setModalEventData(eventData?._id ? { ...eventData } : {
      fboId: "",
      fboName: "",
      airportName: "",
      startDate: new Date(),
      endDate: new Date(),
      price: {
        amount: "",
        currency: ""
      },
      image: "",
      airportID: ""
    })
    setFile(eventData?.image ? eventData.image : defaultImage);
    if (eventData?._id) {
      let ports = airportList.filter(item => item.airportID == eventData.airportID)
      setSelectedAirport(ports[0]);
    }
  }, [show]);

  return (
    <div>
      <Offcanvas backdrop="static" style={{ width: "50%" }} placement="end" show={show} onHide={closeAction} noValidate validated={validated} onSubmit={createEvent}>
        <Offcanvas.Body>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>{modalEventData.fboId ? "Edit" : "Add"} Event</Offcanvas.Title>
          </Offcanvas.Header>
          <div style={{ marginBottom: "10px" }}>
            <div>
              <Image width={"200px"} src={file} rounded />
              {
                uploadingImageLoader ? <Spinner animation="border" size='sm' style={{ color: "#006ba3", marginLeft: "10px", marginRight: "10px" }} /> :
                  <label style={{ fontWeight: "300", color: "#006ba3", cursor: "pointer", marginRight: "10px", marginLeft: "10px" }}>
                    {
                      modalEventData.image ?
                        <DriveFileRenameOutlineIcon fontSize='10px' /> :
                        <ControlPointIcon fontSize='10px' />
                    }
                    <input onChange={uploadImage} style={{ display: "none" }} type="file" />
                    {modalEventData.image ? " Edit" : " Add"}
                  </label>
              }
              <span style={{ fontWeight: "300", color: "red", cursor: "pointer" }} onClick={handleDelete}><DeleteOutlineIcon fontSize='9px' /> Delete</span>
            </div>
          </div>
          <Form>
            <Row>
              <Col>
                <div style={{ position: "relative" }}>
                  <Form.Group style={modalEventData._id ? { display: "" } : {}}>
                    <Form.Label>Airport</Form.Label>
                    <InputGroup>
                      <Form.Control
                        placeholder="Search Airport"
                        value={searchAirportInput}
                        onChange={(e) => setSearchAirportInput(e.target.value)}
                        onFocus={() => { setIsDisplayAirportResults({ display: "block" }) }}
                      />
                      <Button variant="outline-primary" onClick={searchAirport}>
                        Search
                      </Button>
                    </InputGroup>
                  </Form.Group>
                  <div style={filteredAirport?.length > 0 ? { display: "", width: "100%", position: "absolute", zIndex: "999", maxHeight: "500px", overflowY: "scroll" } : { width: "100%", position: "absolute", zIndex: "999" }}>
                    <ListGroup style={{ textAlign: "initial", boxShadow: "4px 5px 9px #808080" }}>
                      {
                        filteredAirport?.map((item) =>
                          <ListGroup.Item action key={item.airportID} onClick={(e) => { e.preventDefault(); setAirport(item) }}>
                            {item.airportID} : {item.airportName}
                          </ListGroup.Item>)
                      }
                    </ListGroup>
                  </div>
                  <div className='mt-2 mb-2'>Airport <span style={{ color: "red" }}>* </span>:
                    <span style={{ color: '#006ba3' }}>{
                      modalEventData._id ? ` ${modalEventData.airportID} : ${selectedAirport?.airportName}`
                        : selectedAirport ? ` ${selectedAirport.airportID} : ${selectedAirport.airportName}` : ""}
                    </span>
                  </div>
                </div>

                <div style={{ position: "relative" }}>
                  <Form.Group className="mt-3">
                    <Form.Label>Select FBO</Form.Label>
                    <InputGroup>
                      <Form.Control
                        placeholder="Search FBO"
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                        // onBlur={() => {setIsDisplayResults({display: "none"})}}
                        onFocus={() => { setIsDisplayResults({ display: "block" }) }}
                      />
                      <Button variant="outline-primary" onClick={searchFBO}>
                      {isLoading && <Spinner as="span" animation="border" size="sm" />}
                      {isLoading ? "" : "Search"}
                      </Button>
                    </InputGroup>
                  </Form.Group>
                  <div style={searchResults?.length > 0 ? { width: "100%", position: "absolute", zIndex: "1" } : { display: "none" }}>
                    <ListGroup style={{ textAlign: "initial", boxShadow: "4px 5px 9px #808080" }}>
                      {
                        searchResults?.map((item) =>
                          <ListGroup.Item action key={item._id} onClick={(e) => { e.preventDefault(); setFBO(item) }}>
                            {item.airportID} : {item.name}
                          </ListGroup.Item>)
                      }
                    </ListGroup>
                  </div>
                  <div className='mt-2 mb-2'>FBO Name :
                    <span style={{ color: '#006ba3' }}>{
                      modalEventData._id ? ` ${modalEventData.airportID} : ${modalEventData.fboName}`
                        : selectedFBO ? ` ${selectedFBO.airportID} : ${selectedFBO.name}` : ""}
                    </span>
                  </div>
                </div>
                <Form.Group className="mb-3">
                  <Form.Label>Price <span style={{ color: "red" }}>*</span></Form.Label>
                  <Form.Control required type='number' min="0" placeholder="Event price" onChange={(e) => setModalEventData({ ...modalEventData, price: { amount: e.target.value, currency: "$" } })} value={modalEventData?.price?.amount} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Location <span style={{ color: "red" }}>*</span></Form.Label>
                  <Form.Control required placeholder="Location" onChange={(e) => setModalEventData({ ...modalEventData, eventLocation: e.target.value })} value={modalEventData.eventLocation} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Select start date & time <span style={{ color: "red" }}>*</span></Form.Label>
                  <Datetime
                    initialValue={moment(modalEventData.startDate).format("MM/DD/YYYY hh:mm A")}
                    // initialViewDate={moment(modalEventData.startDate).format("MM/DD/YYYY hh:mm A")} 
                    isValidDate={dateValidity}
                    onClose={(date) => setStartDate(date)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Select end date & time <span style={{ color: "red" }}>*</span></Form.Label>
                  <Datetime
                    initialValue={moment(modalEventData.endDate).format("MM/DD/YYYY hh:mm A")}
                    // initialViewDate={moment(modalEventData.endDate).format("MM/DD/YYYY hh:mm A")} 
                    isValidDate={dateValidityOfEndDate}
                    onClose={(date) => dateValidator(date)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Name <span style={{ color: "red" }}>*</span></Form.Label>
                  <Form.Control required placeholder="Event Name" onChange={(e) => setModalEventData({ ...modalEventData, name: e.target.value })} value={modalEventData.name} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>About <span style={{ color: "red" }}>*</span></Form.Label>
                  <Form.Control placeholder="About" required onChange={(e) => setModalEventData({ ...modalEventData, about: e.target.value })} value={modalEventData.about} as="textarea" rows={6} />
                </Form.Group>
              </Col>
            </Row>
            <div>
              <Button disabled={disableButtons} variant="outline-secondary" style={{ marginRight: "5px" }} onClick={closeAction}>
                Cancel
              </Button>
              <Button disabled={disableButtons} variant="outline-primary" type='submit'>
                Save
              </Button>
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default EventModal;
