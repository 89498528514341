import React, { useEffect, useState } from 'react'
import { Alert, Card } from 'react-bootstrap'
import logo from './asset/bootsplash_logo_original.png'


export default function PasswordResetMessage() {
  const [passwordResetData, setPasswordResetData] = useState({
    variant: "",
    message: "Successfully reset."
  })
  useEffect(() => {
      let urlResponce = window.location.href.split('password/')[1]
      urlResponce = urlResponce.split('=')
      if (urlResponce[0] == 'error') {
        return setPasswordResetData({...passwordResetData, variant: "danger", message: decodeURI(urlResponce[1])})
      }
      if (window.innerWidth < 700 ) {
        return setPasswordResetData({...passwordResetData, variant: "success", message: decodeURI(urlResponce[1])})
      }
      setPasswordResetData({...passwordResetData, variant: "success", message: decodeURI(urlResponce[1])})    
  }, [])
  return (
    <>
      <Card style={{ width: '500px', margin: '0 auto', marginTop: '5%', alignItems: 'center' }}>
        <Card.Img variant="top" src={logo} style={{ width: '200px'}} />
        <Card.Body>
          <Alert variant={passwordResetData.variant}>
            {passwordResetData.message}
          </Alert>
        </Card.Body>
      </Card>
    </>
  )
}
