import { Modal, Image } from "react-bootstrap";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import "./style.css"

export default function ImagePreview({show, src, handleImagePreviewClose, ...props}) {
  return (
    <>
      <Modal
        show={show}
        src={src}
        keyboard={false}
        animation={true}
        centered={true}
        onHide={handleImagePreviewClose}
        closeButton
      >
        <Modal.Body>
          <span style={{
            position: "absolute",
            top: "-20px",
            left: "100%",
            color: "rgb(186 33 33)",
            cursor: "pointer"
          }} onClick={handleImagePreviewClose}>
            <HighlightOffIcon />
          </span>
          <Image src={src} width="100%"/>
        </Modal.Body>
      </Modal>
    </>
  );
}