import React, { useState } from 'react';
import DateRange from '@mui/icons-material/DateRange';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import LocalAirportIcon from '@mui/icons-material/LocalAirport';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import PersonIcon from '@mui/icons-material/Person';
import SwipeRightIcon from '@mui/icons-material/SwipeRight';
import { Button, Card, Col, Modal, Row } from 'react-bootstrap';
import moment from 'moment';
import axios from 'axios';
import { host, url } from '../../config/common';
import { useAlert } from 'react-alert';
import FboUpdateModal from './fboUpdateModal';

const FboUpdateCard = ({ fboUpdatesData, refreshData, ...props }) => {
  const [showEdit, setShowEdit] = useState(false);
  const [showDeleteModal, setshowDeleteModal] = useState(false);
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [showFullDescription, setFullDescription] = useState(false);
  const alert = useAlert();

  const description = showFullDescription
    ? fboUpdatesData?.fboDetails
    : fboUpdatesData?.fboDetails?.slice(0, 30);

  const showFullDescriptionHandler = () => {
    setFullDescription(!showFullDescription);
  };

  const handleEdit = () => {
    setShowEdit(true)
  }

  const handleClose = () => {
    setShowEdit(false)
  }

  const acceptRejectRequest = async (id, status) => {
    try {
      let eventRequest = await axios({
        method: 'patch',
        headers: { 'x-access-token': JSON.parse(localStorage.getItem('user'))?.token ? JSON.parse(localStorage.getItem('user')).token : "" },
        url: `${host}${url.changeStatusShareAnUpdate}`,
        data: {
          _id: id,
          status: status
        }
      });
      if (eventRequest) {
        alert.show(`Request ${status === 1 ? "accepted" : "rejected"}`, { type: 'success' })
        setshowDeleteModal(false);
        setShowAcceptModal(false);
        refreshData()
      }
    } catch (error) {
      console.log({ error });
    }
  }

  return (
    <div>
      <FboUpdateModal show={showEdit} handleClose={handleClose} fboUpdatesData={fboUpdatesData} refreshData={() => refreshData()} />
      <Modal show={showDeleteModal} onHide={() => setshowDeleteModal(false)} backdrop="static">
        <Modal.Body>Are you sure you want to reject the FBO update request?</Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => setshowDeleteModal(false)}>
            No
          </Button>
          <Button variant="outline-danger" onClick={() => acceptRejectRequest(fboUpdatesData._id, 2)}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showAcceptModal} onHide={() => setShowAcceptModal(false)} backdrop="static">
        <Modal.Body>Are you sure you want to accept the FBO update request?</Modal.Body>
        <Modal.Footer>
          <span>Already existed FBO, Please go to FBO and update accordigly.</span>
          <Button variant="outline-secondary" onClick={() => setShowAcceptModal(false)}>
            No
          </Button>
          <Button variant="outline-danger" onClick={() => acceptRejectRequest(fboUpdatesData._id, 1)}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Card style={{ flexDirection: "initial", marginBottom: "10px" }}>
        <Card.Body style={{ textAlign: "initial" }}>
          <div style={{ display: "flex", justifyContent: "end" }}>
            {fboUpdatesData?.isExisting ?
              <span style={{ fontWeight: "300", color: "#006ba3", marginRight: "10px" }}>Already existed FBO</span>
              : <span style={{ fontWeight: "300", color: "#006ba3", marginRight: "10px" }}>Unlisted FBO</span>
            }
          </div>

          <p style={{ fontWeight: "300" }}><LocalAirportIcon fontSize='10px' />  <b>Airport ID :</b> {fboUpdatesData?.airportID}</p>
          <p style={{ fontWeight: "300" }}><PhoneAndroidIcon fontSize='10px' />  <b>FBO Name :</b> {fboUpdatesData?.fboName}</p>
          <p style={{ fontWeight: "300" }}><ContactMailIcon fontSize='10px' />  <b>FBO Updates :</b> {description}  {fboUpdatesData?.fboDetails?.length > 30 && <span onClick={showFullDescriptionHandler}>
            <b>Read {showFullDescription ? "Less" : "More..."}</b></span>}</p>
          <p style={{ fontWeight: "300" }}><PersonIcon fontSize='10px' />  <b>Requested By :</b> {fboUpdatesData?.requestedBy}</p>
          <p style={{ fontWeight: "300" }}><DateRange fontSize='10px' />  <b>Requested Date :</b> {moment(fboUpdatesData?.createdAt).format("Do MMM YYYY")}</p>
          <Row>
            <Col>
              <div style={{ display: "flex", justifyContent: "end" }}>
                {fboUpdatesData?.status === 0 ?
                  <div>
                    <span style={{ fontWeight: "300", color: "#006ba3", cursor: "pointer", marginRight: "10px" }} onClick={() => fboUpdatesData?.isExisting ? setShowAcceptModal(true) : handleEdit()}><SwipeRightIcon fontSize='9px' /> Accept</span>
                    <span style={{ fontWeight: "300", color: "red", cursor: "pointer" }} onClick={() => setshowDeleteModal(true)}><DeleteOutlineIcon fontSize='9px' /> Reject</span>
                  </div>
                  :
                  fboUpdatesData?.status === 1 ? <span style={{ color: "green" }}>Accepted</span> : <span style={{ color: "red" }}>Rejected</span>
                }

              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
}

export default FboUpdateCard;
