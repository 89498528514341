import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Offcanvas, Row } from 'react-bootstrap';
import FormData from 'form-data'
import { useAlert } from 'react-alert'
import { host, url } from '../../config/common';
import axios from 'axios';
import FileUploadIcon from '@mui/icons-material/FileUpload';

const OnsiteFoodUpdateModal = ({ show, handleClose, foodData, refreshData, ...props }) => {
  const [disableButtons, setDisableButtons] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const initialData = {
    airportID: "",
    fboId: "",
    fboName: "",
    restaurantName: "",
    restaurantAddress: "",
    aboutRestaurant: "",
    restaurantPhone: "",
    restaurantWebsite: "",
    restaurantOperationHours: "",
    restaurantImagesUrls: []
  };
  const [modalEventData, setModalEventData] = useState(foodData?._id ? { ...foodData } : initialData);

  const [validated, setValidated] = useState(false);
  const alert = useAlert();
  const user = localStorage.getItem('user');

  const updateFood = async (e) => {
    e.preventDefault();
    try {
      const form = e.currentTarget;
      console.log("validate", form.checkValidity());
      if (form.checkValidity() === false) {
        e.stopPropagation();
        setValidated(true);
      } else {
        setValidated(true);
        setDisableButtons(true);
        const formData = new FormData();
        formData.append("_id", modalEventData?._id);
        formData.append("fboId", modalEventData?.fboId);
        formData.append("fboName", modalEventData?.fboName);
        formData.append("airportID", modalEventData?.airportID);
        formData.append("restaurantName", modalEventData?.restaurantName);
        formData.append("restaurantPhone", modalEventData?.restaurantPhone);
        formData.append("restaurantWebsite", modalEventData?.restaurantWebsite);
        formData.append("restaurantOperationHours", modalEventData?.restaurantOperationHours);
        formData.append("restaurantAddress", modalEventData?.restaurantAddress);
        formData.append("aboutRestaurant", modalEventData?.aboutRestaurant);
        formData.append("status", 1);
        if (user) {
          formData.append("uid", JSON.parse(user)._id);
        }


        uploadedFiles.forEach((image) => {
          formData.append("restaurantImages", image?.file);
        });
        let updateOnsiteFood = await axios({
          method: 'post',
          headers: { 'x-access-token': JSON.parse(localStorage.getItem('user'))?.token ? JSON.parse(localStorage.getItem('user')).token : "" },
          url: `${host}${url.updateShareAnUpdate}`,
          data: formData
        });
        if (updateOnsiteFood) {
          alert.show("Onsite food updated", { type: 'success' });
          setUploadedFiles([]);
          setModalEventData(initialData);
          refreshData();
          setDisableButtons(false);
          handleClose();
        }
      }
    } catch (error) {
      console.log({ error });
    }
  }

  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files)
    handleUploadFiles(chosenFiles);
  }

  const handleUploadFiles = (files) => {
    const allowedFormats = ["image/jpeg", "image/png", "image/jpg"];
    const filteredFiles = files.filter((file) => allowedFormats.includes(file.type));

    const updatedImages = filteredFiles.map((file) => ({
      file,
      preview: URL.createObjectURL(file),
    }));

    setUploadedFiles((prevImages) => {
      const combinedImages = [...prevImages, ...updatedImages];
      return combinedImages;
    });
  }

  const closeAction = () => {
    if (modalEventData._id) {
      setModalEventData({ ...foodData })
      handleClose()
      return
    }
    setModalEventData(initialData);
    setDisableButtons(false);
    handleClose()
  }

  useEffect(() => {
    setModalEventData(foodData?._id ? { ...foodData } : initialData)
  }, [foodData]);

  return (
    <div>
      <Offcanvas backdrop="static" style={{ width: "50%" }} placement="end" show={show} onHide={closeAction} >
        <Offcanvas.Body>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Edit Onsite Food Request</Offcanvas.Title>
          </Offcanvas.Header>
          <Form noValidate validated={validated} onSubmit={updateFood}>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <div className='upload-files-container'>
                    <div className='drag-file-area'>
                      <span className='upload-icon'>
                        <FileUploadIcon />
                        <input type='file' className='default-file-input' multiple onChange={handleFileEvent} />
                      </span>
                      <label className='label'>
                        <span className='browse-files-text'>Upload Restaurant Images</span>
                      </label>
                    </div>
                  </div>

                </Form.Group>
                <div className="uploaded-files-list mb-3" style={{ display: "flex", flexWrap: "wrap", gap: "30px" }}>
                  {uploadedFiles.map((image, index) => (
                    <div key={index} className='upload_image_show_column' style={{ position: "relative" }}>
                      <img
                        style={{
                          height: "190px",
                          width: "179px",
                        }}
                        src={image.preview}
                        alt={`Preview ${index + 1}`}
                      />
                      <span
                        className='remove-icon'
                        style={{ position: "absolute", cursor: "pointer" }}
                        onClick={() => {
                          setUploadedFiles(uploadedFiles.filter((_, i) => i !== index));
                        }}>
                        &#x2715;
                      </span>
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Airport ID <span style={{ color: "red" }}>*</span></Form.Label>
                  <Form.Control type='text' value={modalEventData.airportID} readOnly />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Restaurant Phone <span style={{ color: "red" }}>*</span></Form.Label>
                  <Form.Control required type='text' placeholder="Restaurant Phone"
                    onChange={(e) => setModalEventData({ ...modalEventData, restaurantPhone: e.target.value })}
                    value={modalEventData.restaurantPhone}
                    pattern="^\d{10}$"
                    isInvalid={
                      validated && !/^\d{10}$/.test(modalEventData.restaurantPhone)
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid 10-digit phone number.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Restaurant Operation Hours <span style={{ color: "red" }}>*</span></Form.Label>
                  <Form.Control required type='text' placeholder="Restaurant Operation Hours" onChange={(e) => setModalEventData({ ...modalEventData, restaurantOperationHours: e.target.value })} value={modalEventData.restaurantOperationHours} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>About Restaurant <span style={{ color: "red" }}>*</span></Form.Label>
                  <Form.Control required as="textarea" rows={3} placeholder="About Restaurant" onChange={(e) => setModalEventData({ ...modalEventData, aboutRestaurant: e.target.value })} value={modalEventData.aboutRestaurant} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>FBO</Form.Label>
                  <Form.Control type='text' value={modalEventData?.fboName} readOnly />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Restaurant Name <span style={{ color: "red" }}>*</span></Form.Label>
                  <Form.Control required type='text' placeholder="Restaurant Name" onChange={(e) => setModalEventData({ ...modalEventData, restaurantName: e.target.value })} value={modalEventData.restaurantName} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Restaurant Website <span style={{ color: "red" }}>*</span></Form.Label>
                  <Form.Control required type='text' placeholder="Restaurant Website" onChange={(e) => setModalEventData({ ...modalEventData, restaurantWebsite: e.target.value })} value={modalEventData.restaurantWebsite} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Restaurant Address <span style={{ color: "red" }}>*</span></Form.Label>
                  <Form.Control required type='text' placeholder="Restaurant Address" onChange={(e) => setModalEventData({ ...modalEventData, restaurantAddress: e.target.value })} value={modalEventData.restaurantAddress} />
                </Form.Group>
              </Col>
            </Row>
            <div>
              <Button disabled={disableButtons} variant="outline-secondary" style={{ marginRight: "5px" }} onClick={closeAction}>
                Cancel
              </Button>
              <Button disabled={disableButtons} variant="outline-primary" type='submit'>
                Save
              </Button>
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default OnsiteFoodUpdateModal;
