import React, { useState } from 'react';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import LocalAirportIcon from '@mui/icons-material/LocalAirport';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import LanguageIcon from '@mui/icons-material/Language';
import { Button, Card, Col, Modal, Row } from 'react-bootstrap';
import axios from 'axios';
import { host, url } from '../../../config/common';
import DefaultImage from '../../asset/bootsplash_logo_original.png';
import OnsiteFoodCreateModal from './onsiteFoodCreateModal';
import { Approval } from '@mui/icons-material';

const OnsiteFoodManageCard = ({ foodData, refreshData, airportList, ...props }) => {
  const [showEdit, setShowEdit] = useState(false);
  const [showDeleteModal, setshowDeleteModal] = useState(false);
  const [showFullDescription, setFullDescription] = useState(false);

  const description = showFullDescription
    ? foodData?.aboutRestaurant
    : foodData?.aboutRestaurant?.slice(0, 50);

  const showFullDescriptionHandler = () => {
    setFullDescription(!showFullDescription);
  };


  const handleEdit = () => {
    setShowEdit(true)
  }

  const handleClose = () => {
    setShowEdit(false)
  }

  const deleteFood = async (id) => {
    try {
      let deletedEvent = await axios({
        method: 'delete',
        headers: { 'x-access-token': JSON.parse(localStorage.getItem('user'))?.token ? JSON.parse(localStorage.getItem('user')).token : "" },
        url: `${host}${url.deleteOnsiteFood}${id}`
      });
      if (deletedEvent) {
        setshowDeleteModal(false)
        refreshData()
      }
    } catch (error) {
      console.log({ error });
    }
  }

  const handleWebsiteClick = (url) => {
    if (url.startsWith('http://') || url.startsWith('https://')) {
      window.open(url, '_blank');
    } else {
      window.open(`http://${url}`, '_blank');
    }
  }

  return (
    <div>
      <OnsiteFoodCreateModal show={showEdit} handleClose={handleClose} foodData={foodData} refreshData={() => refreshData()}  airportList={airportList}/>
      <Modal show={showDeleteModal} onHide={() => setshowDeleteModal(false)} backdrop="static">
        <Modal.Body>Are you sure you want to delete the onsite food?</Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => setshowDeleteModal(false)}>
            No
          </Button>
          <Button variant="outline-danger" onClick={() => deleteFood(foodData._id)}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Card style={{ flexDirection: "initial", marginBottom: "10px" }}>
        <Card.Img style={{ "width": "200px", height: "200px", objectFit: "cover", margin: "1%" }} src={foodData?.restaurantImagesUrls?.length > 0 ? foodData.restaurantImagesUrls[0] : DefaultImage} />
        <Card.Body style={{ textAlign: "initial" }}>
          <p style={{ fontWeight: "300" }}><LocalAirportIcon fontSize='10px' />  <b>Airport ID :</b> {foodData?.airportID} - {foodData.airportName}</p>
          <p style={{ fontWeight: "300" }}><Approval fontSize='10px' /><b>FBO Name :</b> {foodData.fboName}</p>
          <p style={{ fontWeight: "300" }}><RestaurantIcon fontSize='10px' />  <b>Restaurant Name :</b> {foodData?.restaurantName}</p>
          <p style={{ fontWeight: "300" }}><PhoneAndroidIcon fontSize='10px' />  <b>Restaurant Phone :</b> {foodData?.restaurantPhone}</p>
          <p style={{ fontWeight: "300" }}><LanguageIcon fontSize='10px' />  <b>Restaurant Website :</b> {foodData?.restaurantWebsite && <span style={{color:"#0d6efd", cursor: "pointer"}} onClick={() => handleWebsiteClick(foodData?.restaurantWebsite)}>{foodData?.restaurantWebsite}</span>}</p>
          <p style={{ fontWeight: "300" }}><HourglassBottomIcon fontSize='10px' />  <b>Hours of Operation :</b> {foodData?.restaurantOperationHours}</p>
          <p style={{ fontWeight: "300" }}><ContactMailIcon fontSize='10px' />  <b>Address :</b> {foodData?.restaurantAddress}</p>
          <p style={{ fontWeight: "300" }}><ContactMailIcon fontSize='10px' />  <b>About :</b> {description} {foodData?.aboutRestaurant?.length > 50 && <span onClick={showFullDescriptionHandler}>
            <b>Read {showFullDescription ? "Less" : "More..."}</b></span>}</p>
          <Row>
            <Col>
              <div style={{ display: "flex", justifyContent: "end" }}>
                <span style={{ fontWeight: "300", color: "#006ba3", cursor: "pointer", marginRight: "10px" }} onClick={() => handleEdit()}><DriveFileRenameOutlineIcon fontSize='10px' /> Edit</span>
                <span style={{ fontWeight: "300", color: "red", cursor: "pointer" }} onClick={() => setshowDeleteModal(true)}><DeleteOutlineIcon fontSize='9px' /> Delete</span>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
}

export default OnsiteFoodManageCard;
