import React, { useEffect, useMemo, useState } from 'react';
import { Button, Col, Form, InputGroup, ListGroup, Offcanvas, Row, Spinner } from 'react-bootstrap';
import FormData from 'form-data'
import { useAlert } from 'react-alert'
import { host, url } from '../../../config/common';
import axios from 'axios';
import debounce from 'lodash/debounce';

const SocialContentCreateModal = ({ show, handleClose, socialContentData, refreshData, airportList, ...props }) => {
  const [disableButtons, setDisableButtons] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedFBO, setSelectedFBO] = useState("");
  const [isDisplayResults, setIsDisplayResults] = useState({ display: "none" });

  const [searchAirportInput, setSearchAirportInput] = useState("");
  const [selectedAirport, setSelectedAirport] = useState("");
  const [isDisplayAirportResults, setIsDisplayAirportResults] = useState({ display: "none" });
  const [searchTerm, setSearchTerm] = useState('');

  const initialData = {
    airportID: "",
    fboId: "",
    fboName: "",
    titleOfPost: "",
    linkToPost: "",
    socialMediaPlatform: ""
  };
  const [modalEventData, setModalEventData] = useState(socialContentData?._id ? { ...socialContentData } : initialData);

  const [validated, setValidated] = useState(false);
  const alert = useAlert()

  const platformList = ["instagram", "facebook", "youTube"];

  const updateSocialContent = async (e) => {
    e.preventDefault();
    try {
      const form = e.currentTarget;
      console.log("validate", form.checkValidity());
      if (form.checkValidity() === false) {
        e.stopPropagation();
        setValidated(true);
      } else {
        if (modalEventData?.airportID == "") {
          alert.show("Please select an airport.", {type: 'error'})
          return;
        }
        setValidated(true);
        setDisableButtons(true);
        const formData = new FormData();
        let data = {};
        if (modalEventData?._id) {
          data["_id"] = modalEventData?._id;
        }
        data["fboId"] = modalEventData?.fboId;
        data["fboName"] = modalEventData?.fboName;
        data["airportID"] = modalEventData?.airportID;
        data["titleOfPost"] = modalEventData?.titleOfPost;
        data["linkToPost"] = modalEventData?.linkToPost;
        data["socialMediaPlatform"] = modalEventData?.socialMediaPlatform;

        let updateEvent = await axios({
          method: 'post',
          headers: { 'x-access-token': JSON.parse(localStorage.getItem('user'))?.token ? JSON.parse(localStorage.getItem('user')).token : "" },
          url: `${host}${url.createEditSocialMediaContent}`,
          data: data
        });
        if (updateEvent) {
          alert.show("Social media content updated", { type: 'success' });
          setModalEventData(initialData);
          refreshData();
          setDisableButtons(false);
          handleClose();
          setValidated(false);
          setSelectedAirport("");
          setSelectedFBO("");
        }
      }
    } catch (error) {
      console.log({ error });
    }
  }

  const searchFBO = async (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      let searchedFBOs = await axios({
        method: 'get',
        headers: { 'x-access-token': JSON.parse(localStorage.getItem('user'))?.token ? JSON.parse(localStorage.getItem('user')).token : "" },
        url: `${host}${url.searchFBO}?key=${searchInput}&limit=10&skip=0&`
      });
      if (searchedFBOs) {
        setSearchResults(searchedFBOs.data.data)
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log({ error });
      if (error.response.status == 404) {
        alert.show("No FBO found", { type: 'error' })
      }
    }
  }

  const searchAirport = async (e) => {
    e.preventDefault();
    debouncedSetSearchTerm(searchAirportInput);
  }

  const debouncedSetSearchTerm = useMemo(
    () => debounce((value) => setSearchTerm(value), 300),
    [searchAirportInput]
  );

  const filteredAirport = useMemo(() => {
    if (!searchTerm) return [];
    return airportList.filter(airport =>
      airport.airportID.toLowerCase().includes(searchTerm.toLowerCase()) || airport.airportName.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [searchTerm]);

  const setAirport = (item) => {
    setSearchAirportInput("")
    setSearchTerm("")
    setSelectedAirport(item)
    setModalEventData({ ...modalEventData, airportID: item.airportID, fboName: "", fboId: ""})
    setSelectedFBO("");
  }

  const setFBO = (item) => {
    setSearchInput("")
    setSelectedFBO(item)
    setModalEventData({ ...modalEventData, fboId: item._id, fboName: item.name, airportID: item.airportID })
    setSearchResults([])
    let port = airportList.filter(airport =>
      airport.airportID == item.airportID
    );
    setSelectedAirport(port[0]);
  }

  const closeAction = () => {
    if (modalEventData._id) {
      setModalEventData({ ...socialContentData })
      handleClose()
      return
    } else {
      setSearchResults([]);
      setSearchInput("");
      setSelectedFBO("");
      setValidated(false);
      setSearchAirportInput("")
      setSearchTerm("")
      setSelectedAirport("")
    }
    setModalEventData(initialData);
    setDisableButtons(false)
    handleClose()
  }

  useEffect(() => {
    setModalEventData(socialContentData?._id ? { ...socialContentData } : initialData)
    if (socialContentData?._id) {
      let port = airportList.filter(item => item.airportID == socialContentData.airportID)
      setSelectedAirport(port[0]);
    }
  }, [socialContentData]);

  return (
    <div>
      <Offcanvas backdrop="static" style={{ width: "50%" }} placement="end" show={show} onHide={closeAction} >
        <Offcanvas.Body>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>{modalEventData?._id ? "Edit" : "Add"} Social Medial Content</Offcanvas.Title>
          </Offcanvas.Header>
          <Form noValidate validated={validated} onSubmit={updateSocialContent}>
            <Row>
              <Col>
              <div style={{ position: "relative" }}>
                  <Form.Group style={modalEventData._id ? { display: "" } : {}}>
                    <Form.Label>Airport</Form.Label>
                    <InputGroup>
                      <Form.Control
                        placeholder="Search Airport"
                        value={searchAirportInput}
                        onChange={(e) => setSearchAirportInput(e.target.value)}
                        onFocus={() => { setIsDisplayAirportResults({ display: "block" }) }}
                      />
                      <Button variant="outline-primary" onClick={searchAirport}>
                        Search
                      </Button>
                    </InputGroup>
                  </Form.Group>
                  <div style={filteredAirport?.length > 0 ? { display: "", width: "100%", position: "absolute", zIndex: "999", maxHeight: "500px", overflowY: "scroll" } : { width: "100%", position: "absolute", zIndex: "999"}}>
                    <ListGroup style={{ textAlign: "initial", boxShadow: "4px 5px 9px #808080" }}>
                      {
                        filteredAirport?.map((item) =>
                          <ListGroup.Item action key={item.airportID} onClick={(e) => { e.preventDefault(); setAirport(item) }}>
                            {item.airportID} : {item.airportName}
                          </ListGroup.Item>)
                      }
                    </ListGroup>
                  </div>
                  <div className='mt-2 mb-2'>Airport <span style={{ color: "red" }}>* </span>:
                    <span style={{ color: '#006ba3' }}>{
                      modalEventData._id ? ` ${modalEventData.airportID} : ${selectedAirport?.airportName}`
                        : selectedAirport ? ` ${selectedAirport.airportID} : ${selectedAirport.airportName}` : ""}
                    </span>
                  </div>
                </div>

                <div style={{ position: "relative" }}>
                  <Form.Group>
                    <Form.Label>Select FBO</Form.Label>
                    <InputGroup>
                      <Form.Control
                        placeholder="Search FBO"
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                        onFocus={() => { setIsDisplayResults({ display: "block" }) }}
                      />
                      <Button variant="outline-primary" onClick={searchFBO}>
                        {isLoading && <Spinner as="span" animation="border" size="sm" />}
                        {isLoading ? "" : "Search"}
                      </Button>
                    </InputGroup>
                  </Form.Group>
                  <div style={modalEventData._id || !searchInput ? { display: "", width: "100%", position: "absolute", zIndex: "1" } : { width: "100%", position: "absolute", zIndex: "1" }}>
                    <ListGroup style={{ textAlign: "initial", boxShadow: "4px 5px 9px #808080" }}>
                      {
                        searchResults?.map((item) =>
                          <ListGroup.Item action key={item._id} onClick={(e) => { e.preventDefault(); setFBO(item) }}>
                            {item.airportID} : {item.name}
                          </ListGroup.Item>)
                      }
                    </ListGroup>
                  </div>
                  <div className='mt-2 mb-2' style={modalEventData?._id ? { paddingBottom: "16px", paddingTop: "30px" } : {}}>FBO Name :
                    <span style={{ color: '#006ba3' }}>{
                      modalEventData._id ? ` ${modalEventData.airportID} : ${modalEventData.fboName}`
                        : selectedFBO ? ` ${selectedFBO.airportID} : ${selectedFBO.name}` : ""}
                    </span>
                  </div>
                </div>
                <Form.Group className="mb-3">
                  <Form.Label>Post Title <span style={{ color: "red" }}>*</span></Form.Label>
                  <Form.Control required type='text' placeholder="Post Title" onChange={(e) => setModalEventData({ ...modalEventData, titleOfPost: e.target.value })} value={modalEventData?.titleOfPost} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" style={{ paddingBottom: "24px" }}>
                  <Form.Label>Media Platform <span style={{ color: "red" }}>*</span></Form.Label>
                  <Form.Control
                    as="select"
                    value={modalEventData.socialMediaPlatform}
                    required
                    onChange={(e) => setModalEventData({ ...modalEventData, socialMediaPlatform: e.target.value })}
                  >
                    {platformList.map((item) => {
                      let name = item.charAt(0).toUpperCase() + item.slice(1);
                      return <option key={item} value={name} >{name}</option>
                    })}
                  </Form.Control>
                </Form.Group>
                <Form.Group className="mb-3 mt-3">
                  <Form.Label>Social Post Link <span style={{ color: "red" }}>*</span></Form.Label>
                  <Form.Control required type='text' placeholder="Post Link" onChange={(e) => setModalEventData({ ...modalEventData, linkToPost: e.target.value })} value={modalEventData?.linkToPost} />
                </Form.Group>
              </Col>
            </Row>
            <div>
              <Button disabled={disableButtons} variant="outline-secondary" style={{ marginRight: "5px" }} onClick={closeAction}>
                Cancel
              </Button>
              <Button disabled={disableButtons} variant="outline-primary" type='submit'>
                Save
              </Button>
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default SocialContentCreateModal;
