import { Modal } from "react-bootstrap";
import { Grid } from "react-loader-spinner";
import "./style.css"

export default function Loader({show, ...props}) {
  return (
    <>
      <Modal
        show={show}
        backdrop="static"
        keyboard={false}
        animation={true}
        centered={true}
      >
        <Modal.Body>
          <Grid arialLabel="loading-indicator"/>
        </Modal.Body>
      </Modal>
    </>
  );
}