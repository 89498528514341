import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Offcanvas, Row } from 'react-bootstrap';
import FormData from 'form-data'
import { useAlert } from 'react-alert'
import moment from 'moment';
import { host, url } from '../../config/common';
import axios from 'axios';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Datetime from 'react-datetime';

const CoolEventUpdateModal = ({ show, handleClose, coolEventsData, airportList, refreshData, ...props }) => {
  const [disableButtons, setDisableButtons] = useState(false);
  const [isFileUpload, setIsFileUpload] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const initalData = {
    airportID: "",
    fboId: "",
    fboName: "",
    name: "",
    startDate: new Date(),
    endDate: new Date(),
    about: "",
    eventLocation: "",
    price: {
      amount: ""
    },
    restaurantImagesUrls: []
  };
  const [modalEventData, setModalEventData] = useState(coolEventsData?._id ? { ...coolEventsData } : initalData);

  const [validated, setValidated] = useState(false);
  const alert = useAlert();
  const user = localStorage.getItem('user');

  const updateCoolEvent = async (e) => {
    e.preventDefault();
    try {
      const form = e.currentTarget;
      console.log("validate", form.checkValidity());
      if (form.checkValidity() === false) {
        e.stopPropagation();
        setValidated(true);
      } else {
        setValidated(true);

        if(dateValidator()) return;
        setDisableButtons(true);
        const formData = new FormData();
        formData.append("_id", modalEventData?._id);
        formData.append("fboId", modalEventData?.fboId);
        formData.append("fboName", modalEventData?.fboName);
        formData.append("airportID", modalEventData?.airportID);
        formData.append("eventLocation", modalEventData?.eventLocation);
        formData.append("startDate", modalEventData?.startDate);
        formData.append("endDate", modalEventData?.endDate);
        formData.append("about", modalEventData?.about);
        formData.append("price", JSON.stringify(modalEventData?.price));
        formData.append("name", modalEventData?.name);
        formData.append("status", 1);
        if (user) {
          formData.append("uid", JSON.parse(user)._id);
        }

        uploadedFiles.forEach((image) => {
          formData.append("restaurantImages", image?.file);
        });
        let updateEvent = await axios({
          method: 'post',
          headers: { 'x-access-token': JSON.parse(localStorage.getItem('user'))?.token ? JSON.parse(localStorage.getItem('user')).token : "" },
          url: `${host}${url.updateShareAnUpdate}`,
          data: formData
        });
        if (updateEvent) {
          alert.show("Cool event updated", { type: 'success' });
          setUploadedFiles([]);
          setModalEventData(initalData);
          refreshData();
          setIsFileUpload(false);
          setDisableButtons(false);
          handleClose();
        }
      }
    } catch (error) {
      console.log({ error });
    }
  }

  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files)
    handleUploadFiles(chosenFiles);
  }

  const handleUploadFiles = (files) => {
    const allowedFormats = ["image/jpeg", "image/png", "image/jpg"];
    const filteredFiles = files.filter((file) => allowedFormats.includes(file.type));

    if (filteredFiles && filteredFiles.length === 0) {
      return;
    }
    const updatedImages = filteredFiles.map((file) => ({
      file,
      preview: URL.createObjectURL(file),
    }));
    setIsFileUpload(true);
    setUploadedFiles((prevImages) => {
      const combinedImages = [...updatedImages];
      return combinedImages;
    });
  }

  const dateValidity = (current) => {
    let yesterday = moment().subtract(1, 'day');
    return current.isAfter(yesterday);
  };

  const dateValidityOfEndDate = (current) => {
    let yesterday = moment().subtract(1, 'day');
    return current.isAfter(yesterday);
  };

  const dateValidator = (date) => {
    let startDateValidator = moment(moment(modalEventData.startDate).format())
    let endDateValidator = moment(moment(date ? date : modalEventData.endDate).format())
    if (!endDateValidator.isAfter(startDateValidator)) {
      alert.show("Please select a valid end date", { type: "error", })
      return true
    }
    setModalEventData({ ...modalEventData, endDate: date })
    return false
  }

  const setStartDate = (date) => {
    let currentDate = moment(new Date()).format();
    if (!date.isAfter(currentDate)) {
      setModalEventData({ ...modalEventData, startDate: currentDate, endDate: currentDate })
      alert.show("Please select a valid start date", { type: "error", })
      return true
    }
    setModalEventData({ ...modalEventData, startDate: date, endDate: date })
    return false
  }

  const closeAction = () => {
    if (modalEventData._id) {
      setModalEventData({ ...coolEventsData })
      handleClose()
      return
    }
    setModalEventData(initalData)
    setDisableButtons(false)
    handleClose()
  }

  useEffect(() => {
    setModalEventData(coolEventsData?._id ? { ...coolEventsData } : initalData)
  }, [coolEventsData]);

  return (
    <div>
      <Offcanvas backdrop="static" style={{ width: "50%" }} placement="end" show={show} onHide={closeAction} >
        <Offcanvas.Body>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Edit Cool Event Request</Offcanvas.Title>
          </Offcanvas.Header>
          <Form noValidate validated={validated} onSubmit={updateCoolEvent}>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <div className='upload-files-container'>
                    <div className='drag-file-area'>
                      <span className='upload-icon'>
                        <FileUploadIcon />
                        <input type='file' className='default-file-input' onChange={handleFileEvent} />
                      </span>
                      <label className='label'>
                        <span className='browse-files-text'>Upload Cool Event Image </span>
                      </label>
                    </div>
                  </div>

                </Form.Group>
                <div className="uploaded-files-list mb-3" style={{ display: "flex", flexWrap: "wrap", gap: "30px" }}>
                  {uploadedFiles.map((image, index) => (
                    <div key={index} className='upload_image_show_column' style={{ position: "relative" }}>
                      <img
                        style={{
                          height: "190px",
                          width: "179px",
                        }}
                        src={image.preview}
                        alt={`Preview ${index + 1}`}
                      />
                      <span
                        className='remove-icon'
                        style={{ position: "absolute", cursor: "pointer" }}
                        onClick={() => {
                          setUploadedFiles(uploadedFiles.filter((_, i) => i !== index));
                        }}>
                        &#x2715;
                      </span>
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Airport ID <span style={{ color: "red" }}>*</span></Form.Label>
                  <Form.Control type='text' value={modalEventData.airportID} readOnly />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Event Name <span style={{ color: "red" }}>*</span></Form.Label>
                  <Form.Control required type='text' placeholder="Event Name" onChange={(e) => setModalEventData({ ...modalEventData, name: e.target.value })} value={modalEventData?.name} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Select start date & time <span style={{ color: "red" }}>*</span></Form.Label>
                  <Datetime
                    initialValue={moment(modalEventData.startDate).format("MM/DD/YYYY hh:mm A")}
                    isValidDate={dateValidity}
                    onClose={(date) => setStartDate(date)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Event Details <span style={{ color: "red" }}>*</span></Form.Label>
                  <Form.Control required as="textarea" placeholder="Event Details" onChange={(e) => setModalEventData({ ...modalEventData, about: e.target.value })} value={modalEventData?.about}  rows={3} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>FBO</Form.Label>
                  <Form.Control type='text' value={modalEventData?.fboName} readOnly />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Event Price <span style={{ color: "red" }}>*</span></Form.Label>
                  <Form.Control required type='number' min="0" placeholder="Event Price" onChange={(e) => setModalEventData({ ...modalEventData, price: { amount: e.target.value, currency: "$" } })} value={modalEventData?.price?.amount} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Event Location <span style={{ color: "red" }}>*</span></Form.Label>
                  <Form.Control required type='text' placeholder="Event Location" onChange={(e) => setModalEventData({ ...modalEventData, eventLocation: e.target.value })} value={modalEventData?.eventLocation} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Select end date & time <span style={{ color: "red" }}>*</span></Form.Label>
                  <Datetime
                    initialValue={moment(modalEventData.endDate).format("MM/DD/YYYY hh:mm A")}
                    isValidDate={dateValidityOfEndDate}
                    onClose={(date) => dateValidator(date)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <div>
              <Button disabled={disableButtons} variant="outline-secondary" style={{ marginRight: "5px" }} onClick={closeAction}>
                Cancel
              </Button>
              <Button disabled={disableButtons} variant="outline-primary" type='submit'>
                Save
              </Button>
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default CoolEventUpdateModal;
