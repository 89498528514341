import { Button, Grid, Input } from '@mui/material';
import React, { useState } from 'react';
import icon from './../asset/bootsplash_logo_original.png'
import axios from 'axios';
import { host, url } from './../../config/common';
import { useAlert } from 'react-alert'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
const DeleteAccount = () => {
    const alert = useAlert();
    const [phone, setPhoneNumber] = useState("");
    const [attemptSid, setAttemptSid] = useState("");
    const [countryCode, setCountryCode] = useState(''); // Country code
    const [emailDeleted, setEmailDeleted] = useState(false);
    const [otpSent, setOtpSent] = useState(false);
    const [otp, setOTP] = useState(false);
    const deleteAccount = async (e) => {
        e.preventDefault();
        if (otp.length != 6) {
            alert.show("Please enter a valid OTP", { type: 'error' });
            return false;
        }
        try {
            let d = "+" + phone;
            let newPhone = d.replace(countryCode, '');
            let user = await axios({
                method: 'post',
                url: `${host}${url.userSupport}`,
                data: {
                    code: otp,
                    phone: newPhone,
                    attemptSid: attemptSid
                }
            })
            if (user.status === 200) {
                setEmailDeleted(true);
                alert.show("Account deleted successfully", { type: 'success' })
            }
        } catch (err) {
            console.log('error::', err)
            alert.show(err.response.data.message, { type: 'error' })
        }
    }

    const sendOtp = async (e) => {
        e.preventDefault();
        if (phone.length < 10) {
            alert.show("Please enter a valid number", { type: 'error' });
            return false;
        }
        try {
            let d = "+" + phone;
            let newPhone = d.replace(countryCode, '');
            let user = await axios({
                method: 'post',
                url: `${host}${url.sendOtp}`,
                data: {
                    phone: newPhone,
                    countryCode: countryCode
                }
            })
            if (user.status === 200) {
                setAttemptSid(user.data.attemptSid);
                setOtpSent(true);
                alert.show("OTP Sent successfully", { type: 'success' })
            }
        } catch (err) {
            console.log('error::', err)
            alert.show(err.response.data.message, { type: 'error' })
        }
    }
    const handlePhoneChange = (value, countryData) => {
        setCountryCode(`+${countryData.dialCode}`); // Set country code
        setPhoneNumber(value.replace(`+${countryData.dialCode}`, '')); // Remove country code from full value
    };
    return (
        <div>
            <Grid container>
                <Grid md={12} sx={{ padding: "4% 10% 52px 10%" }}>
                    <h2>Account Deletion</h2>
                    <h5>Hi there,</h5>
                    <p>
                        If you’re considering bidding farewell to FBO, we understand it’s a tough decision. Should you choose to delete your account, you can do so directly from the app. Rest assured, your privacy is of utmost importance to us. If you wish, we can also erase all data associated with your account, including the information used during your initial installation and sign-in process.
                    </p>
                    <p>
                        To initiate the process, could you please provide your email address? This will allow us to delete your registered email address.
                    </p>
                    <p>
                        Before you go, we’d love to hear any feedback you might have. Your insights help us improve and better serve our users. If there's anything we can do to address your concerns or improve your experience, please leave a review on app-store and play-store.
                    </p>
                    <p>
                        Thank you for being a part of the FBO community. We hope to see you again in the future. If you ever decide to return, we’ll be here to welcome you back with open arms.
                    </p>
                </Grid>
                <Grid sm={12} md={12} sx={{ textAlign: "center", marginBottom: "20px" }}>
                    {
                        emailDeleted ?
                            <div style={{ padding: "0px 200px", color: "Green" }}>Account deleted successfully.</div> :
                            otpSent ?
                                <div>
                                    <div style={{ padding: "0px 200px", marginBottom: "10px" }}>Please Enter OTP</div>
                                    <form onSubmit={deleteAccount} style={{ display: "flex", justifyContent: "center", flexDirection: "column", gap: "10px", alignItems: "center" }}>
                                        <Input type="number" max="6" sx={{ marginRight: "10px", marginBottom: "10px" }} placeholder='Enter OTP' onChange={(e) => setOTP(e.target.value)} required />
                                        <Button type="submit" variant='contained' color='error'>Delete Account</Button>
                                    </form>
                                </div>
                                :
                                <div>
                                    <div >
                                        <form onSubmit={sendOtp} style={{ display: "flex", justifyContent: "center", flexDirection: "column", gap: "10px", alignItems: "center" }}>
                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                <PhoneInput
                                                    country={'us'} // Default country
                                                    value={phone}
                                                    onChange={(value, countryData) => handlePhoneChange(value, countryData)}
                                                    placeholder="Enter phone number"
                                                    required={true}
                                                />
                                            </div>

                                            <Button style={{ width: "200px" }} type="submit" variant='contained' color='error'>Send OTP</Button>
                                        </form>
                                    </div>
                                </div>
                    }
                </Grid>
            </Grid>
        </div>
    );
}
export default DeleteAccount;