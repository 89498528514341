import React from 'react'
import { ListGroup, Navbar, Nav, NavDropdown, Container } from 'react-bootstrap'
import GridViewIcon from '@mui/icons-material/GridView';
import GroupIcon from '@mui/icons-material/Group';
import LocalAirportIcon from '@mui/icons-material/LocalAirport';
import RateReviewIcon from '@mui/icons-material/RateReview';
import SummarizeIcon from '@mui/icons-material/Summarize';
import LockIcon from '@mui/icons-material/Lock';
import Approval from '@mui/icons-material/Approval';
import AddedFBO from '@mui/icons-material/AirplaneTicket';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import InterestsIcon from '@mui/icons-material/Interests';
import LocalDiningIcon from '@mui/icons-material/LocalDining';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import OfflineShareIcon from '@mui/icons-material/OfflineShare';

export default class Sidebar extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      items: [
        { id: 1, icon: <GridViewIcon />, value: 'Dashboard', active: true },
        { id: 2, icon: <GroupIcon />, value: 'Users Management', active: false },
        { id: 3, icon: <RateReviewIcon />, value: 'Review Management', active: false },
        { id: 4, icon: <LocalAirportIcon />, value: 'Airports/FBO Management', active: false },
        { id: 5, icon: <LocalActivityIcon />, value: 'Event Management', active: false },
        { id: 6, icon: <Approval />, value: 'FBO Claims', active: false },
        { id: 7, icon: <AddedFBO />, value: 'Added FBO', active: false },
        { id: 8, icon: <SummarizeIcon />, value: 'Terms and Conditions', active: false },
        { id: 9, icon: <LockIcon />, value: 'Privacy Policy', active: false },
        { id: 18, icon: <OfflineShareIcon />, value: 'Share An Update Requests', active: false },
        // {id: 15, icon: <AddedFBO />, value: 'Share An Update Requests', active: false, dropDownValue: [
        //     { id: 10, icon: <RestaurantIcon />, value: 'Onsite Food Requests', active: false },
        //     { id: 11, icon: <LocalActivityIcon />, value: 'Cool Event Requests', active: false },
        //     { id: 12, icon: <RecentActorsIcon />, value: 'Unlisted Service Requests', active: false },
        //     { id: 13, icon: <AddedFBO />, value: 'FBO Update Requests', active: false },
        //     { id: 14, icon: <InterestsIcon />, value: 'Social Media Requests', active: false },
        //   ]
        // },
        { id: 16, icon: <LocalDiningIcon />, value: 'Onsite Food Management', active: false },
        { id: 17, icon: <InterestsIcon />, value: 'Social Media Management', active: false },
      ],
      sidebarURL: "",
      isDropOpen: false
    }
  }

  updateSidebarOnURLChange() {
    if (this.state.sidebarURL != window.location.href) {
      this.sidebarUpdate()
      this.setState({ sidebarURL: window.location.href })
    }
  }

  componentDidMount() {
    this.sidebarUpdate()
  }
  sidebarUpdate() {
    let sidebarLocation = window.location.href.split('/home')
    if (sidebarLocation[1] === '/userList' || sidebarLocation[1].split('?')[0] === '/userList') {
      this.setState({
        items: [
          { id: 1, icon: <GridViewIcon />, value: 'Dashboard', active: false },
          { id: 2, icon: <GroupIcon />, value: 'Users Management', active: true },
          { id: 3, icon: <RateReviewIcon />, value: 'Review Management', active: false },
          { id: 4, icon: <LocalAirportIcon />, value: 'Airports/FBO Management', active: false },
          { id: 5, icon: <LocalActivityIcon />, value: 'Event Management', active: false },
          { id: 6, icon: <Approval />, value: 'FBO Claims', active: false },
          { id: 7, icon: <AddedFBO />, value: 'Added FBO', active: false },
          { id: 8, icon: <SummarizeIcon />, value: 'Terms and Conditions', active: false },
          { id: 9, icon: <LockIcon />, value: 'Privacy Policy', active: false },
          { id: 18, icon: <OfflineShareIcon />, value: 'Share An Update Requests', active: false },
          { id: 16, icon: <LocalDiningIcon />, value: 'Onsite Food Management', active: false },
          { id: 17, icon: <InterestsIcon />, value: 'Social Media Management', active: false },
        ]
      })
    }
    if (sidebarLocation[1] === '/airportData') {
      this.setState({
        items: [
          { id: 1, icon: <GridViewIcon />, value: 'Dashboard', active: false },
          { id: 2, icon: <GroupIcon />, value: 'Users Management', active: false },
          { id: 3, icon: <RateReviewIcon />, value: 'Review Management', active: false },
          { id: 4, icon: <LocalAirportIcon />, value: 'Airports/FBO Management', active: true },
          { id: 5, icon: <LocalActivityIcon />, value: 'Event Management', active: false },
          { id: 6, icon: <Approval />, value: 'FBO Claims', active: false },
          { id: 7, icon: <AddedFBO />, value: 'Added FBO', active: false },
          { id: 8, icon: <SummarizeIcon />, value: 'Terms and Conditions', active: false },
          { id: 9, icon: <LockIcon />, value: 'Privacy Policy', active: false },
          { id: 18, icon: <OfflineShareIcon />, value: 'Share An Update Requests', active: false },
          { id: 16, icon: <LocalDiningIcon />, value: 'Onsite Food Management', active: false },
          { id: 17, icon: <InterestsIcon />, value: 'Social Media Management', active: false },
        ]
      })
    }
    if (sidebarLocation[1] === '/terms&condition') {
      this.setState({
        items: [
          { id: 1, icon: <GridViewIcon />, value: 'Dashboard', active: false },
          { id: 2, icon: <GroupIcon />, value: 'Users Management', active: false },
          { id: 3, icon: <RateReviewIcon />, value: 'Review Management', active: false },
          { id: 4, icon: <LocalAirportIcon />, value: 'Airports/FBO Management', active: false },
          { id: 5, icon: <LocalActivityIcon />, value: 'Event Management', active: false },
          { id: 6, icon: <Approval />, value: 'FBO Claims', active: false },
          { id: 7, icon: <AddedFBO />, value: 'Added FBO', active: false },
          { id: 8, icon: <SummarizeIcon />, value: 'Terms and Conditions', active: true },
          { id: 9, icon: <LockIcon />, value: 'Privacy Policy', active: false },
          { id: 18, icon: <OfflineShareIcon />, value: 'Share An Update Requests', active: false },
          { id: 16, icon: <LocalDiningIcon />, value: 'Onsite Food Management', active: false },
          { id: 17, icon: <InterestsIcon />, value: 'Social Media Management', active: false },
        ]
      })
    }
    if (sidebarLocation[1] === '/privacyPolicy') {
      this.setState({
        items: [
          { id: 1, icon: <GridViewIcon />, value: 'Dashboard', active: false },
          { id: 2, icon: <GroupIcon />, value: 'Users Management', active: false },
          { id: 3, icon: <RateReviewIcon />, value: 'Review Management', active: false },
          { id: 4, icon: <LocalAirportIcon />, value: 'Airports/FBO Management', active: false },
          { id: 5, icon: <LocalActivityIcon />, value: 'Event Management', active: false },
          { id: 6, icon: <Approval />, value: 'FBO Claims', active: false },
          { id: 7, icon: <AddedFBO />, value: 'Added FBO', active: false },
          { id: 8, icon: <SummarizeIcon />, value: 'Terms and Conditions', active: false },
          { id: 9, icon: <LockIcon />, value: 'Privacy Policy', active: true },
          { id: 18, icon: <OfflineShareIcon />, value: 'Share An Update Requests', active: false },
          { id: 16, icon: <LocalDiningIcon />, value: 'Onsite Food Management', active: false },
          { id: 17, icon: <InterestsIcon />, value: 'Social Media Management', active: false },
        ]
      })
    }
    if (sidebarLocation[1] === '/queries') {
      this.setState({
        items: [
          { id: 1, icon: <GridViewIcon />, value: 'Dashboard', active: false },
          { id: 2, icon: <GroupIcon />, value: 'Users Management', active: false },
          { id: 3, icon: <RateReviewIcon />, value: 'Review Management', active: false },
          { id: 4, icon: <LocalAirportIcon />, value: 'Airports/FBO Management', active: false },
          { id: 5, icon: <LocalActivityIcon />, value: 'Event Management', active: false },
          { id: 6, icon: <Approval />, value: 'FBO Claims', active: false },
          { id: 7, icon: <AddedFBO />, value: 'Added FBO', active: false },
          { id: 8, icon: <SummarizeIcon />, value: 'Terms and Conditions', active: false },
          { id: 9, icon: <LockIcon />, value: 'Privacy Policy', active: false },
          { id: 18, icon: <OfflineShareIcon />, value: 'Share An Update Requests', active: false },
          { id: 16, icon: <LocalDiningIcon />, value: 'Onsite Food Management', active: false },
          { id: 17, icon: <InterestsIcon />, value: 'Social Media Management', active: false },
        ]
      })
    }
    if (sidebarLocation[1] === '/reviewManagement') {
      this.setState({
        items: [
          { id: 1, icon: <GridViewIcon />, value: 'Dashboard', active: false },
          { id: 2, icon: <GroupIcon />, value: 'Users Management', active: false },
          { id: 3, icon: <RateReviewIcon />, value: 'Review Management', active: true },
          { id: 4, icon: <LocalAirportIcon />, value: 'Airports/FBO Management', active: false },
          { id: 5, icon: <LocalActivityIcon />, value: 'Event Management', active: false },
          { id: 6, icon: <Approval />, value: 'FBO Claims', active: false },
          { id: 7, icon: <AddedFBO />, value: 'Added FBO', active: false },
          { id: 8, icon: <SummarizeIcon />, value: 'Terms and Conditions', active: false },
          { id: 9, icon: <LockIcon />, value: 'Privacy Policy', active: false },
          { id: 18, icon: <OfflineShareIcon />, value: 'Share An Update Requests', active: false },
          { id: 16, icon: <LocalDiningIcon />, value: 'Onsite Food Management', active: false },
          { id: 17, icon: <InterestsIcon />, value: 'Social Media Management', active: false },
        ]
      })
    }
    if (sidebarLocation[1] === '/fboClaims') {
      this.setState({
        items: [
          { id: 1, icon: <GridViewIcon />, value: 'Dashboard', active: false },
          { id: 2, icon: <GroupIcon />, value: 'Users Management', active: false },
          { id: 3, icon: <RateReviewIcon />, value: 'Review Management', active: false },
          { id: 4, icon: <LocalAirportIcon />, value: 'Airports/FBO Management', active: false },
          { id: 5, icon: <LocalActivityIcon />, value: 'Event Management', active: false },
          { id: 6, icon: <Approval />, value: 'FBO Claims', active: true },
          { id: 7, icon: <AddedFBO />, value: 'Added FBO', active: false },
          { id: 8, icon: <SummarizeIcon />, value: 'Terms and Conditions', active: false },
          { id: 9, icon: <LockIcon />, value: 'Privacy Policy', active: false },
          { id: 18, icon: <OfflineShareIcon />, value: 'Share An Update Requests', active: false },
          { id: 16, icon: <LocalDiningIcon />, value: 'Onsite Food Management', active: false },
          { id: 17, icon: <InterestsIcon />, value: 'Social Media Management', active: false },
        ]
      })
    }
    if (sidebarLocation[1] === '/addedFbo') {
      this.setState({
        items: [
          { id: 1, icon: <GridViewIcon />, value: 'Dashboard', active: false },
          { id: 2, icon: <GroupIcon />, value: 'Users Management', active: false },
          { id: 3, icon: <RateReviewIcon />, value: 'Review Management', active: false },
          { id: 4, icon: <LocalAirportIcon />, value: 'Airports/FBO Management', active: false },
          { id: 5, icon: <LocalActivityIcon />, value: 'Event Management', active: false },
          { id: 6, icon: <Approval />, value: 'FBO Claims', active: false },
          { id: 7, icon: <AddedFBO />, value: 'Added FBO', active: true },
          { id: 8, icon: <SummarizeIcon />, value: 'Terms and Conditions', active: false },
          { id: 9, icon: <LockIcon />, value: 'Privacy Policy', active: false },
          { id: 18, icon: <OfflineShareIcon />, value: 'Share An Update Requests', active: false },
          { id: 16, icon: <LocalDiningIcon />, value: 'Onsite Food Management', active: false },
          { id: 17, icon: <InterestsIcon />, value: 'Social Media Management', active: false },
        ]
      })
    }
    if (sidebarLocation[1] === '/eventManagement') {
      this.setState({
        items: [
          { id: 1, icon: <GridViewIcon />, value: 'Dashboard', active: false },
          { id: 2, icon: <GroupIcon />, value: 'Users Management', active: false },
          { id: 3, icon: <RateReviewIcon />, value: 'Review Management', active: false },
          { id: 4, icon: <LocalAirportIcon />, value: 'Airports/FBO Management', active: false },
          { id: 5, icon: <LocalActivityIcon />, value: 'Event Management', active: true },
          { id: 6, icon: <Approval />, value: 'FBO Claims', active: false },
          { id: 7, icon: <AddedFBO />, value: 'Added FBO', active: false },
          { id: 8, icon: <SummarizeIcon />, value: 'Terms and Conditions', active: false },
          { id: 9, icon: <LockIcon />, value: 'Privacy Policy', active: false },
          { id: 18, icon: <OfflineShareIcon />, value: 'Share An Update Requests', active: false },
          { id: 16, icon: <LocalDiningIcon />, value: 'Onsite Food Management', active: false },
          { id: 17, icon: <InterestsIcon />, value: 'Social Media Management', active: false },
        ]
      })
    }

    if (sidebarLocation[1] === '/onsiteFoodManagement') {
      this.setState({
        items: [
          { id: 1, icon: <GridViewIcon />, value: 'Dashboard', active: false },
          { id: 2, icon: <GroupIcon />, value: 'Users Management', active: false },
          { id: 3, icon: <RateReviewIcon />, value: 'Review Management', active: false },
          { id: 4, icon: <LocalAirportIcon />, value: 'Airports/FBO Management', active: false },
          { id: 5, icon: <LocalActivityIcon />, value: 'Event Management', active: false },
          { id: 6, icon: <Approval />, value: 'FBO Claims', active: false },
          { id: 7, icon: <AddedFBO />, value: 'Added FBO', active: false },
          { id: 8, icon: <SummarizeIcon />, value: 'Terms and Conditions', active: false },
          { id: 9, icon: <LockIcon />, value: 'Privacy Policy', active: false },
          { id: 18, icon: <OfflineShareIcon />, value: 'Share An Update Requests', active: false },
          { id: 16, icon: <LocalDiningIcon />, value: 'Onsite Food Management', active: true },
          { id: 17, icon: <InterestsIcon />, value: 'Social Media Management', active: false },
        ]
      })
    }
    if (sidebarLocation[1] === '/socialMediaManagement') {
      this.setState({
        items: [
          { id: 1, icon: <GridViewIcon />, value: 'Dashboard', active: false },
          { id: 2, icon: <GroupIcon />, value: 'Users Management', active: false },
          { id: 3, icon: <RateReviewIcon />, value: 'Review Management', active: false },
          { id: 4, icon: <LocalAirportIcon />, value: 'Airports/FBO Management', active: false },
          { id: 5, icon: <LocalActivityIcon />, value: 'Event Management', active: false },
          { id: 6, icon: <Approval />, value: 'FBO Claims', active: false },
          { id: 7, icon: <AddedFBO />, value: 'Added FBO', active: false },
          { id: 8, icon: <SummarizeIcon />, value: 'Terms and Conditions', active: false },
          { id: 9, icon: <LockIcon />, value: 'Privacy Policy', active: false },
          { id: 18, icon: <OfflineShareIcon />, value: 'Share An Update Requests', active: false },
          { id: 16, icon: <LocalDiningIcon />, value: 'Onsite Food Management', active: false },
          { id: 17, icon: <InterestsIcon />, value: 'Social Media Management', active: true },
        ]
      })
    }
    if (sidebarLocation[1] === '/shareAndUpdateRequests') {
      this.setState({
        items: [
          { id: 1, icon: <GridViewIcon />, value: 'Dashboard', active: false },
          { id: 2, icon: <GroupIcon />, value: 'Users Management', active: false },
          { id: 3, icon: <RateReviewIcon />, value: 'Review Management', active: false },
          { id: 4, icon: <LocalAirportIcon />, value: 'Airports/FBO Management', active: false },
          { id: 5, icon: <LocalActivityIcon />, value: 'Event Management', active: false },
          { id: 6, icon: <Approval />, value: 'FBO Claims', active: false },
          { id: 7, icon: <AddedFBO />, value: 'Added FBO', active: false },
          { id: 8, icon: <SummarizeIcon />, value: 'Terms and Conditions', active: false },
          { id: 9, icon: <LockIcon />, value: 'Privacy Policy', active: false },
          { id: 18, icon: <OfflineShareIcon />, value: 'Share An Update Requests', active: true },
          { id: 16, icon: <LocalDiningIcon />, value: 'Onsite Food Management', active: false },
          { id: 17, icon: <InterestsIcon />, value: 'Social Media Management', active: false },
        ]
      })
    }
  }

  pannelSelection(key, value) {
    let navigate = this.props.navigate
    let newSidebarItemsList = []

    this.state.items.forEach((element) => {
      element.active = false
      if (element.value === value) {
        element.active = true
      }
      newSidebarItemsList.push(element)
    })
    this.setState({ items: newSidebarItemsList })
    if (value === 'Onsite Food Requests' || value === 'Cool Event Requests' || value === 'Unlisted Service Requests'
      || value === 'FBO Update Requests' || value === 'Social Media Requests') {
      this.setState({ isDropOpen: true});
    } else {
      this.setState({ isDropOpen: false});
    }
    switch (value) {
      case 'Dashboard':
        navigate('/home/')
        break;
      case 'Users Management':
        navigate('/home/userList')
        break;
      case 'Airports/FBO Management':
        navigate('/home/airportData')
        break;
      case 'Query Management':
        navigate('/home/airportData')
        break;
      case 'Terms and Conditions':
        navigate('/home/terms&condition')
        break;
      case 'Privacy Policy':
        navigate('/home/privacyPolicy')
        break;
      case 'Review Management':
        navigate('/home/reviewManagement')
        break;
      case 'FBO Claims':
        navigate('/home/fboClaims')
        break;
      case 'Added FBO':
        navigate('/home/addedFbo')
        break;
      case 'Event Management':
        navigate('/home/eventManagement')
        break;
      case 'Onsite Food Management':
        navigate('/home/onsiteFoodManagement')
        break;
      case 'Social Media Management':
        navigate('/home/socialMediaManagement')
        break;
      case 'Share An Update Requests':
          navigate('/home/shareAndUpdateRequests')
          break;
      default:
        break;
    }
  }

  render() {
    this.updateSidebarOnURLChange()
    return (
      <>
        <ListGroup style={this.props.hoverStyle.display == "none" ? {} : { textAlign: 'left', backgroundColor: "white" }}>
          {
            this.state.items.map((item) => {
              return item?.dropDownValue ?
              <>
                <ListGroup.Item style={item.active ? { border: "0px", backgroundColor: "#2283b5", display:"block" } : { border: "0px", color: "#469cc6",display:"block" }} key={item.id} action active={item.active} onClick={() => this.setState({ isDropOpen: !this.state.isDropOpen })}>
                  <span>{item.icon}</span>
                  <span style={this.props.hoverStyle}><span>{item.value}
                  
                  
                  { this.state.isDropOpen
                    ? <ArrowDropUpIcon />
                    :  <ArrowDropDownIcon />
                  }
                  </span>
                  </span>
                  
                </ListGroup.Item>
                <ListGroup style={this.state.isDropOpen ? { textAlign: 'left', backgroundColor: "white", display: "block", padding: "0px 15px" } : {display: "none"}}>
                  {item?.dropDownValue.map((dropItem) => {
                    return <ListGroup.Item style={dropItem.active 
                    ? this.props.hoverStyle.display == "none" ? { border: "0px", backgroundColor: "#2283b5", marginTop: "4px", display: "flex", justifyContent:"center",  } : { border: "0px", backgroundColor: "#2283b5", marginTop: "4px" } 
                    : this.props.hoverStyle.display == "none" ? { border: "0px", color: "#469cc6", marginTop: "4px", display: "flex", justifyContent:"center"} : { border: "0px", color: "#469cc6", marginTop: "4px"}} key={dropItem.id} action active={dropItem.active} onClick={() => this.pannelSelection(dropItem.key, dropItem.value)}>
                      <span style={{marginRight: "6px"}}>{dropItem.icon}</span> <span style={this.props.hoverStyle}>{dropItem.value}</span>
                    </ListGroup.Item>
                  })}
                </ListGroup>
                </>
                :
                <ListGroup.Item style={item.active ? { border: "0px", backgroundColor: "#2283b5" } : { border: "0px", color: "#469cc6" }} key={item.id} action active={item.active} onClick={() => this.pannelSelection(item.key, item.value)}>
                  <span>{item.icon}</span> <span style={this.props.hoverStyle}>{item.value}</span>
                </ListGroup.Item>
            })
          }
        </ListGroup>
      </>
    )
  }
}
