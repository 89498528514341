import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { host, url } from "../../config/common";
import axios from "axios";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useAlert } from "react-alert";
import * as XLSX from "xlsx";
import TokenExpireHandler from "./tokenExpireHandler";

export default function UserExcelDownloader(props) {
  const { show, navigate, onHide } = props;
  const alert = useAlert();
  var wb = XLSX.utils.book_new();

  const fetchAllUserData = async () => {
    try {
      onHide()
      alert.show("Download will begin automatically in some time.", {type: "success"})
      let allUsers = await axios({
      method: 'get',
      headers: {'x-access-token': JSON.parse(localStorage.getItem('user'))?.token ? JSON.parse(localStorage.getItem('user')).token : ""},
        url: `${host}${url.getAllUsers}/0And0`
      });
      downloadxls(allUsers.data.allUsers)
      // rearangeData(allUsers.data, item, false)
    } catch (e) {
      console.log('error', e)
      TokenExpireHandler(navigate, e)
    }
  }

  const downloadxls = async (sheetData)=>{
    try {
      let keysForArray = Object.keys(sheetData[0])
      let processedData = {}
      let headerProcess = keysForArray.map((item) => 
      new Promise((resolve) => {
        processedData[item] = item
        resolve()
      })
      )
      await Promise.all(headerProcess)
      sheetData.unshift(processedData)
      let ws = XLSX.utils.json_to_sheet(sheetData, {skipHeader: true});
      XLSX.utils.book_append_sheet(wb, ws, "FBO-Users");
      XLSX.writeFile(wb, `FBO-Users-Data.xlsx`);
    } catch (e) {
      console.log('e', e)
    }
  }
  return (
    <>
      <Modal
        {...props}
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h4>Do you want to export user data to excel.</h4>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="success" onClick={fetchAllUserData}>
            {" "}
            <FileDownloadIcon /> Export
          </Button>
          <Button onClick={onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
