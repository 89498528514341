import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { host, url } from '../../config/common';
import axios from 'axios';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useAlert } from 'react-alert';
import * as XLSX from 'xlsx'
import TokenExpireHandler from './tokenExpireHandler';

export default function CsvDownloader({hideModal, navigate}) {
  const [states, setStates] = useState(null)
  const alert = useAlert()
  let wb = XLSX.utils.book_new(); 

  useEffect(() => {
    if (states) {
      fetchAllAirports()
    }
  }, [states])

  const fetchAllStatesName = async () => {
    try {
      let statesList = await axios({  
        method: 'get',  
        headers: {'x-access-token': JSON.parse(localStorage.getItem('user'))?.token ? JSON.parse(localStorage.getItem('user')).token : ""},
        url: `${host}${url.getAllStates}`,
      });
      setStates(statesList)
    } catch (e) {
      console.log(e)
      TokenExpireHandler(navigate, e)
    }
  }

  const fetchAllAirports = async () => {
    try {
      hideModal()
      alert.show("Download will begin automatically in some time.", {type: "success"})
      let fetchStateData = states.data.map((item, index) => 
        new Promise(async (resolve, reject) => {
          try {
            if (item) {
              let allUsers = await axios({  
              method: 'get',  
              headers: {'x-access-token': JSON.parse(localStorage.getItem('user'))?.token ? JSON.parse(localStorage.getItem('user')).token : ""},
                url: `${host}${url.searchAirport}${item}`,
                params: {searchStateForExporting: true}
        
              });
              rearangeData(allUsers.data, item, false)
              if (states.data.length == index + 1) {
                debugger
                rearangeData([], "last", true)
              }
              resolve()
            }
          } catch (error) {
            reject()
            console.log({error});
          }
        })
      )
      await Promise.all(fetchStateData)
      // .then(() => {
        rearangeData([], "last", true)
      // })
    } catch (e) {
      console.log('error', e)
      TokenExpireHandler(navigate, e)
    }
  }

  async function rearangeData(data, sheetName, isAllStatesFetched){
    try {
      data.sort(function(a, b) {
        return b.fbo.length - a.fbo.length;
      });
      let fboPropsPromises =  data.map(element => 
        new Promise(resolve => {
          if(element.fbo.length){
            for (let index = 1; index <= element.fbo.length; index++) {
              element[`FBO ${index} Name`] = element.fbo[index-1].name 
              element[`FBO ${index} Contact Number`] = element.fbo[index-1].contactNumber 
              element[`FBO ${index} ASRI`] = element.fbo[index-1].ASRI 
              element[`FBO ${index} Website`] = element.fbo[index-1].website 
            }
          }
          delete element.fbo
          resolve()
        })
      );
      await Promise.all(fboPropsPromises)
      // .then(() => {
        // debugger
        downloadxls(data, sheetName, isAllStatesFetched)
      // })
    } catch (e) {
      console.log('e', e)
    }
  }

  const downloadxls = (data, sheetName, isAllStatesFetched)=>{
    try {
      if (isAllStatesFetched) {
        // alert("ojkkjkj")
        XLSX.writeFile(wb, `FBO-Data.xlsx`);
        debugger
        return
      }
      let sheetData = []
      data.forEach((item, index) => {
        if (index == 0) {
          item["Latitude"] = item.location.latitude
          item["Longitude"] = item.location.longitude
          //deleting location key to prevent adding location colum
          delete item.location
          sheetData.push(Object.keys(item))
          let AIDIndex = sheetData[0].indexOf("airportID")
          sheetData[0][AIDIndex] = "Airport ID"
          let ANameIndex = sheetData[0].indexOf("airportName")
          sheetData[0][ANameIndex] = "Airport Name"
          let ADetailsIndex = sheetData[0].indexOf("airportDetails")
          sheetData[0][ADetailsIndex] = "Airport Details"
          let UseTypeIndex = sheetData[0].indexOf("useType")
          sheetData[0][UseTypeIndex] = "Use Type"
          let facilityTypeIndex = sheetData[0].indexOf("facilityType")
          sheetData[0][facilityTypeIndex] = "Facility Type"
          let locationLat = sheetData[0].indexOf("Latitude")
          sheetData[0][locationLat] = "Latitude"
          let locationLong = sheetData[0].indexOf("Longitude")
          sheetData[0][locationLong] = "Longitude"
          return 
        }
        sheetData.push(Object.values(item))
      })
      let ws = XLSX.utils.json_to_sheet(sheetData, {skipHeader: true});
      XLSX.utils.book_append_sheet(wb, ws, sheetName);
    } catch (e) {
      console.log('e', e)
    }
  }
  return (
    <>
      <Button variant="success"  onClick={() => fetchAllStatesName()}> <FileDownloadIcon /> Export DB Excel</Button>
    </>
  )
}
