import React, { Component } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Button } from 'react-bootstrap';
import axios from 'axios';
import { host, url } from '../../../config/common';
import Loader from '../../utils/loader';
import TokenExpireHandler from '../../utils/tokenExpireHandler';

export default class TermsAndCondition extends Component {
  constructor(props){
    super(props)
    this.state = {
      termsAndCondition: "",
      showloader: false
    }
  }
  componentDidMount() {
    this.getTermsAndCondition()
  }

  async getTermsAndCondition () {
    try {
      this.setState({showloader: true})
      let fetchedData = await axios({  
        method: 'get',
        headers: {'x-access-token': JSON.parse(localStorage.getItem('user'))?.token ? JSON.parse(localStorage.getItem('user')).token : ""},
        url: `${host}${url.getTermsAndCondition}`,
      });
      if(fetchedData.status == 401){
        localStorage.removeItem("user")
        return this.props.navigate("/")
      }
      this.setState({showloader: false})
      if (fetchedData.data) {
        this.setState({termsAndCondition: fetchedData.data.result.data})
      }
    } catch (e) {
      console.log(e,"error");
      TokenExpireHandler(this.props.navigate, e)
    }
  }

  async saveTermsAndCondition () {
    try {
      this.setState({showloader: true})
      let dataToSave ={termsAndCondition: this.state.termsAndCondition}
      let fetchedData = await axios({  
        method: 'post',
        headers: {'x-access-token': JSON.parse(localStorage.getItem('user'))?.token ? JSON.parse(localStorage.getItem('user')).token : ""},
        url: `${host}${url.termsAndCondition}`,
        data: dataToSave
      });
      if(fetchedData.status == 401){
        localStorage.removeItem("user")
        return this.props.navigate("/")
      }
      this.setState({showloader: false})
      this.props.alert.show("Saved Succesfully.", {type: 'success'})
    } catch (e) {
      console.log(e,"error");
      TokenExpireHandler(this.props.navigate, e)
    }
  }
  render() {
    return (
      <div>
        <Loader show={this.state.showloader}/>
        <h2>Terms And Conditions</h2>
        <CKEditor
          editor={ClassicEditor}
          config={{
            toolbar: {
              items: [
                'heading',
                '|',
                'bold',
                'italic',
                '|',
                'bulletedList',
                'numberedList',
                '|',
                'insertTable',
                '|',
                'undo',
                'redo',
                'link'
              ]
            },
          }}
          data={this.state.termsAndCondition}
          onChange={(event, editor) => {
            const data = editor.getData();
            this.setState({termsAndCondition: data})
          }}
        />
        <Button style={{marginTop: "2%"}} onClick={this.saveTermsAndCondition.bind(this)}>Save Terms And Condition</Button>
      </div>
    );
  }
}
