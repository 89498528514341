import axios from 'axios'
import React,{ useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import {Modal, Button, Form, Row, Col} from 'react-bootstrap'
import { host, url } from '../../config/common'

export default function ClaimStatusModal({claimData, show, approve, reRenderFn, selectedFbo, hideOffcanvas, ...props}) {
  const [rejection, setRejection] = useState("")
  const [ApprovedCheck, setApprovedCheck] = useState(false)
  const [disbaleButton, setdisbaleButton] = useState(false)
  const alert = useAlert()

  const claimAction = async () => {
    try {
      if (!rejection) {
        return
      }
      setdisbaleButton(true)
      await axios({
        method: "patch",
        headers: {'x-access-token': JSON.parse(localStorage.getItem('user'))?.token ? JSON.parse(localStorage.getItem('user')).token : ""},
        url: !claimData.createdBy ? `${host}${approve ? url.approveClaim : url.rejectClaim}` : `${host}${approve ? url.approveAddFBO : url.rejectAddFBO}`,
        data: {
          fboId: selectedFbo.fboId,
          claimId: claimData._id,
          reason: rejection
        }
      })
      alert.show(`Claim ${approve? "approve" : "rejected"}`, {type: 'success'})
      reRenderFn()
      hideOffcanvas()
      handleClose()
    } catch (e) {
      console.log({e})
      if (e.response.status) {
        hideOffcanvas()
        handleClose()
        reRenderFn()
        return alert.show(e.response.data, {type: 'error'})
      }
      alert.show("Someting went wrong.", {type: 'error'})
    }
  }
  const approveClaim = async () => {
    try {
      setdisbaleButton(true)
      await axios({
        method: "patch",
        headers: {'x-access-token': JSON.parse(localStorage.getItem('user'))?.token ? JSON.parse(localStorage.getItem('user')).token : ""},
        url: !claimData.createdBy ? `${host}${approve ? url.approveClaim : url.rejectClaim}` : `${host}${approve ? url.approveAddFBO : url.rejectAddFBO}`,
        // url: `${host}${aproove ? url.approveClaim : url.rejectClaim}`,
        data: {
          fboId: selectedFbo.fboId,
          claimId: claimData._id,
          reason: rejection
        }
      })
      alert.show(`Claim ${approve? "Approved" : "rejected"}`, {type: 'success'})
      reRenderFn()
      hideOffcanvas()
      handleClose()
    } catch (e) {
      console.log({e: e.response})
      if (e.response.status) {
        hideOffcanvas()
        handleClose()
        reRenderFn()
        return alert.show(e.response.data, {type: 'error'})
      }
      alert.show("Someting went wrong.", {type: 'error'})
    }
  }
  const handleClose = () => {
    setRejection()
    props.onHide()
  }

  useEffect(() => {
    setdisbaleButton(false)
  }, [claimData]);
  return (
    <>
      <Modal
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Form onSubmit={(e) => e.preventDefault()}>
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              Do you want to {approve ? "Approved" : "reject"} claim request?
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Row>
              <Col md="4">
                <Form.Group className="mb-3">
                  <Form.Label>User name</Form.Label>
                  <Form.Control placeholder={claimData.userName} disabled />
                </Form.Group>
              </Col>
              <Col md="4">
                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control placeholder={claimData.email} disabled />
                </Form.Group>
              </Col>
              <Col md="4">
                <Form.Group className="mb-3">
                  <Form.Label>Contact Number</Form.Label>
                  <Form.Control placeholder={claimData.contactNumber} disabled />
                </Form.Group>
              </Col>
            </Row>
            <h3>FBO Details</h3>
            {
              claimData.createdBy ?
              <Row>
                <Col md="4">
                  <Form.Group className="mb-3">
                    <Form.Label>FBO Name</Form.Label>
                    <Form.Control placeholder={claimData.name} disabled />
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group className="mb-3">
                    <Form.Label>Airport ID</Form.Label>
                    <Form.Control placeholder={claimData.airportID} disabled />
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group className="mb-3">
                    <Form.Label>FBO Contact Number</Form.Label>
                    <Form.Control placeholder={claimData.contactNumber} disabled />
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group className="mb-3">
                    <Form.Label>FBO ASRI</Form.Label>
                    <Form.Control placeholder={claimData.ASRI} disabled />
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group className="mb-3">
                    <Form.Label>Facility Type</Form.Label>
                    <Form.Control placeholder={claimData.facilityType} disabled />
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group className="mb-3">
                    <Form.Label>City</Form.Label>
                    <Form.Control placeholder={claimData.city} disabled />
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group className="mb-3">
                    <Form.Label>State</Form.Label>
                    <Form.Control placeholder={claimData.state} disabled />
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group className="mb-3">
                    <Form.Label>Facility Name</Form.Label>
                    <Form.Control placeholder={claimData.facilityName} disabled />
                  </Form.Group>
                </Col>
              </Row> : <></>
            }
            
            <Row key={selectedFbo._id}>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>FBO Id</Form.Label>
                  <Form.Control placeholder={selectedFbo.fboId} disabled />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>FBO Name</Form.Label>
                  <Form.Control placeholder={selectedFbo.fboName} disabled />
                </Form.Group>
              </Col>
            </Row>
              {
                approve ? "" : 
                <Form.Group>
                  <Form.Label>Reason to reject the request</Form.Label>
                  <Form.Control value={rejection} onChange={(e) => setRejection(e.target.value)} type="text" placeholder="Rejection reason" required />
                  <Form.Control.Feedback type="invalid">
                    Please provide rejection reason.
                  </Form.Control.Feedback>
                </Form.Group>
              }
          </Modal.Body>
          <Modal.Footer>
            {
              approve ?
              <Button variant='success' disabled={disbaleButton} onClick={approveClaim}>Approve</Button> :
              <Button variant='danger' disabled={disbaleButton} onClick={claimAction} type="submit">Reject</Button>
            }
            <Button variant='secondary' onClick={() => handleClose()}>Cancel</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}