import 'bootstrap/dist/css/bootstrap.min.css';
import "react-datetime/css/react-datetime.css";

import './App.css';
import { Routes, Route } from "react-router-dom";

import Header from './components/header';
import Body from './components/body';
import Login from './components/auth/login';
import PasswordResetMessage from './components/passwordResetMessage';
import {Chart, registerables} from 'chart.js'
import { Alert } from 'react-bootstrap';
import DeleteAccount from './components/auth/deleteAccount';
Chart.register(...registerables);

function App() {
  if(window.innerWidth < 696){
    return(
      <Alert variant="warning">
        This website is not for mobile. Please view it in desktop/laptop.
      </Alert>
    )
  }
  return (
    <div className="App">
      <Header/>
      <Routes>
        <Route path="/password/*" element={<PasswordResetMessage/>} />
        <Route path="/" element={<Login/>} />
        <Route path="/user-support" element={<DeleteAccount/>} />
        <Route path="/home/*" element={<Body />} />
      </Routes>
    </div>
  );
}

export default App;
