import React, { useEffect, useState } from 'react'
import {Modal, Button} from 'react-bootstrap'

export default function AirportDeletionModal(props) {
  const [modalData, setmodalData] = useState({
    isAirport: false, 
    name: "",
    id: ""
  })
  useEffect(() => {
    if (props.user) {
      setmodalData({name: props.user.userName, id: props.user.email })
      return 
    }
    if (props.airport) {
      setmodalData({name: props.airport.airportName, id: props.airport.airportID, isAirport: true })
    }
  }, [props])
  
  return (
    <Modal
      show={props.show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Are you sure you want to delete the {modalData.isAirport? "airport" : "user"} data?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          The data of {modalData.name ? `"${modalData.name} with"` : "" } {modalData.isAirport? "id" : "email"} "{modalData.id}" will be deleted.
        </p>
        <p style={{color: "red"}}>
          Warning! this action can't be undone.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={props.deletionfunction}>
          Delete
        </Button>
        <Button onClick={props.onHide}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  );
}