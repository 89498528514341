import React,{useState, useEffect} from 'react'
import {Modal, Button, Image, Row, Col, Form} from 'react-bootstrap'
// import avatar from './../asset/avatar.webp'
import axios from 'axios'
import { useAlert } from 'react-alert'

import { url, host } from '../../config/common'
import TokenExpireHandler from './tokenExpireHandler'

export default function ProfileSettings({navigate, userProfile, ...props}) {
  const alert = useAlert()
  const [userData, userDataOperation] = useState(userProfile)

  useEffect(() => {
    if (localStorage.getItem("user")) {
      userDataOperation(JSON.parse(localStorage.getItem("user")))
    }
  }, [])
  

  let updateUserProfile = async () => {
    try {
      let updatedUser = await axios({  
        method: 'patch',
        headers: {'x-access-token': JSON.parse(localStorage.getItem('user'))?.token ? JSON.parse(localStorage.getItem('user')).token : ""},
        url: `${host}${url.updateUserById}`,
        data: userData  
      });
      alert.show("Profile updated.", {type: 'success'})
      localStorage.setItem("user", JSON.stringify(updatedUser.data))
      props.onHide()
    } catch (e) {
      console.log(e);
      TokenExpireHandler(navigate, e)
    }
  } 
  let resetProfileData = () => {
    userDataOperation({ ...userProfile })
    props.onHide()
  }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Your Account
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={updateUserProfile}>
          <Row>
            {/* <Col lg="4">
              <Image rounded={'true'} src={avatar} width={"100%"}/>
            </Col> */}
            <Col >
              <Form.Group className="mb-2">
                <Form.Label>First Name</Form.Label>
                <Form.Control 
                  value={userData?.firstName}
                  onChange={e => {
                    userDataOperation({ ...userData, firstName: e.target.value });
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Label>Last Name</Form.Label>
                <Form.Control 
                  value={userData?.lastName}
                  onChange={e => {
                    userDataOperation({ ...userData, lastName: e.target.value });
                  }}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Email address</Form.Label>
                <Form.Control 
                  disabled
                  readOnly
                  value={userData?.email}
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='success' onClick={updateUserProfile}>Save</Button>
        <Button variant="danger" onClick={resetProfileData}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  );
}