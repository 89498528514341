import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Badge, Button, ButtonGroup, ButtonToolbar, Card, Form, FormControl, InputGroup, Table } from 'react-bootstrap';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Cancel from '@mui/icons-material/CancelOutlined';

import { host, url } from '../../../config/common';
import ClaimInfo from '../../utils/claimInfo';
import Loader from '../../utils/loader'
import { useNavigate } from 'react-router-dom';
import TokenExpireHandler from '../../utils/tokenExpireHandler';

export default function AddedFBO() {
  const [claimData, setclaimData] = useState([])
  const [searchValue, setSearchValue] = useState("")
  const [showloader, setShowloader] = useState(true)
  const [paginationRender, setPaginationRender] = useState(1)
  const [limit, setLimit] = useState(0)
  const [skip, setSkip] = useState(0)
  const navigate = useNavigate()
  async function getClaimData () {
    try {
      setShowloader(true)
      console.log("asaasdsadasdasd", `${host}${url.getAllAddFBO}`);
      let fetchedClaimData = await axios({  
        method: 'get',
        headers: {'x-access-token': JSON.parse(localStorage.getItem('user'))?.token ? JSON.parse(localStorage.getItem('user')).token : ""},
        url: `${host}${url.getAllAddFBO}`,
        params: {
          limit,
          skip
        }
      });
      if (fetchedClaimData.status == 200) {
        setclaimData(fetchedClaimData.data)
        setShowloader(false)
      }
    } catch (error) {
      TokenExpireHandler(navigate, error)
      console.log({error});
    }
  }

  function paginationCount(){
    let paginations = []
    for (let index = 1; index <= paginationRender; index++) {
      paginations.push(<Button variant='light' onClick={() => {setSkip(index * 20); getClaimData()}}>{index}</Button>)
    }
    
    return [paginations[paginations.length - 5], paginations[paginations.length - 4],paginations[paginations.length - 3], paginations[paginations.length - 2], paginations[paginations.length - 1]]
  }

  async function paginationAction(clickedAction) {
    setShowloader(true)
    if (clickedAction == "previous") {
      setPaginationRender(paginationRender - 1)
      if (searchValue) {
        setSkip(skip - 20)
        return searchClaim()
      }
      setSkip(skip - 20) 
      getClaimData()
    }
    if (clickedAction == "next") {
      setPaginationRender(paginationRender + 1)
      if (searchValue) {
        setSkip(skip + 20)
        return searchClaim()
      }
      setSkip(skip + 20) 
      getClaimData()
    }
  }

  const handleEmpty = async (value) => {
    setSearchValue("")
    if (!value) {
      alert("value")
      getClaimData()
    }
  }

  async function searchClaim (key) {
    try {
      if (!searchValue) {
        return
      }
      setShowloader(true)
      console.log("asaasdsadasdasd", `${host}${url.getAllAddFBO}`);
      let fetchedClaimData = await axios({  
        method: 'get',
        headers: {'x-access-token': JSON.parse(localStorage.getItem('user'))?.token ? JSON.parse(localStorage.getItem('user')).token : ""},
        url: `${host}${url.getAllAddFBO}`,
        params: {
          key: searchValue
        }
      });
      setShowloader(false)
      if (fetchedClaimData.status == 200) {
        setclaimData(fetchedClaimData.data)
        setShowloader(false)
      }
    } catch (error) {
      console.log(error);
      TokenExpireHandler(navigate, error)
      setShowloader(false)
    }
  }
  useEffect(() => {
    getClaimData()
  }, [])
  
  return (
    <>
      <Card>
        <Loader show={showloader}/>
        <Card.Body>
          {/* <Form style={{marginBottom: "3%"}} onSubmit={(e) =>{e.preventDefault(); searchClaim()}}>
            <InputGroup style={{width: "500px", float: "left"}}>
              <FormControl
                placeholder="Search by Claim Id, User name or email"
                onChange={(e) => setSearchValue(e.target.value)}
                value={searchValue}
              />
              <Button variant='light' style={searchValue?
                {borderTop: "1px solid #cacaca", borderBottom: "1px solid #cacaca", display: "block"}:
                {borderTop: "1px solid #cacaca", borderBottom: "1px solid #cacaca", display: "none"}} onClick={(e => handleEmpty(e.target.value))}>
                <Cancel/>
              </Button>
              <Button variant="outline-primary" type='submit'>
                Search
              </Button>
            </InputGroup>
          </Form> */}
            {
              claimData.length? 
              <Table striped bordered hover >
                <thead>
                  <tr>
                    <th>Sr. No.</th>
                    <th>Claim Id</th>
                    <th>User name</th>
                    <th>Email</th>
                    <th>Approved/Rejected</th>
                    <th>Edit</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    claimData?.map((item, index) =>
                      <tr key={item._id}>
                        <td>{index+1}</td>
                        <td>{item._id}</td>
                        <td>{item.userName}</td>
                        <td>{item.email}</td>
                        <td>
                          {
                            item.approved ?
                            <Badge bg="success">Approved</Badge> :
                            item.rejected ?
                              <Badge bg="danger">Rejected</Badge>:
                              <Badge>Pending</Badge>
                          }
                        </td>
                        <td>
                          <ClaimInfo
                            name="editore"
                            claimData={item}
                            placement="end"
                            reRenderFn={() => getClaimData()}
                          />
                          {/* <Button variant="outline-danger" onClick={() => this.setState({deleteModalShow: true, airportDataForDeletion: airport})}>
                            <DeleteIcon />
                          </Button> */}
                        </td>
                      </tr>
                    )
                  }
                </tbody>
              </Table>: 
              "No data found."
            }
            {/* <ButtonToolbar aria-label="Toolbar with button groups" style={{justifyContent: 'center'}}>
              <ButtonGroup className="me-2" aria-label="First group">
                <Button disabled={skip === 0 } onClick={() => paginationAction("previous")}><ArrowBackIosNewIcon /> Previous page</Button>
                {paginationCount()}
                <Button disabled={claimData.length < 20 } onClick={() => paginationAction("next")}>Next page <ArrowForwardIosIcon/></Button>
              </ButtonGroup>
            </ButtonToolbar> */}
        </Card.Body>
      </Card>
    </>
  )
}
