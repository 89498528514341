import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Form, Offcanvas, Row } from 'react-bootstrap';
import FormData from 'form-data'
import { useAlert } from 'react-alert'
import { host, url } from '../../config/common';
import axios from 'axios';

const SocialContentUpdateModal = ({ show, handleClose, socialContentData, refreshData, ...props }) => {
  const [disableButtons, setDisableButtons] = useState(false);
  const initialData = {
    airportID: "",
    fboId: "",
    fboName: "",
    titleOfPost: "",
    linkToPost: "",
    socialMediaPlatform: ""
  };
  const [modalEventData, setModalEventData] = useState(socialContentData?._id ? { ...socialContentData } : initialData);

  const platformList = ["instagram", "facebook", "youTube"];

  const [validated, setValidated] = useState(false);
  const alert = useAlert()

  const updateSocialContent = async (e) => {
    e.preventDefault();
    try {
      const form = e.currentTarget;
      console.log("validate", form.checkValidity());
      if (form.checkValidity() === false) {
        e.stopPropagation();
        setValidated(true);
      } else {
        setValidated(true);
        setDisableButtons(true);
        const formData = new FormData();
        formData.append("_id", modalEventData?._id);
        formData.append("fboId", modalEventData?.fboId);
        formData.append("fboName", modalEventData?.fboName);
        formData.append("airportID", modalEventData?.airportID);
        formData.append("titleOfPost", modalEventData?.titleOfPost);
        formData.append("linkToPost", modalEventData?.linkToPost);
        formData.append("socialMediaPlatform", modalEventData?.socialMediaPlatform);
        formData.append("status", 1);

        let updateEvent = await axios({
          method: 'post',
          headers: { 'x-access-token': JSON.parse(localStorage.getItem('user'))?.token ? JSON.parse(localStorage.getItem('user')).token : "" },
          url: `${host}${url.updateShareAnUpdate}`,
          data: formData
        });
        if (updateEvent) {
          alert.show("Social media content updated", { type: 'success' });
          setModalEventData(initialData);
          refreshData();
          setDisableButtons(false);
          handleClose();
        }
      }
    } catch (error) {
      console.log({ error });
    }
  }

  const closeAction = () => {
    if (modalEventData._id) {
      setModalEventData({ ...socialContentData })
      handleClose()
      return
    }
    setModalEventData(initialData)
    setDisableButtons(false)
    handleClose()
  }

  useEffect(() => {
    setModalEventData(socialContentData?._id ? { ...socialContentData } : initialData)
  }, [socialContentData]);

  return (
    <div>
      <Offcanvas backdrop="static" style={{ width: "50%" }} placement="end" show={show} onHide={closeAction} >
        <Offcanvas.Body>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Edit Social Content Request</Offcanvas.Title>
          </Offcanvas.Header>
          <Form noValidate validated={validated} onSubmit={updateSocialContent}>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Airport ID <span style={{ color: "red" }}>*</span></Form.Label>
                  <Form.Control type='text' value={modalEventData.airportID} readOnly />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Post Title <span style={{ color: "red" }}>*</span></Form.Label>
                  <Form.Control required type='text' placeholder="Post Title" onChange={(e) => setModalEventData({ ...modalEventData, titleOfPost: e.target.value })} value={modalEventData?.titleOfPost} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>FBO</Form.Label>
                  <Form.Control type='text' value={modalEventData?.fboName} readOnly />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Media Platform <span style={{ color: "red" }}>*</span></Form.Label>
                  <Form.Control
                    as="select"
                    value={modalEventData.socialMediaPlatform}
                    required
                    onChange={(e) => setModalEventData({ ...modalEventData, socialMediaPlatform: e.target.value })}
                  >
                    {platformList.map((item) => {
                      let name = item.charAt(0).toUpperCase() + item.slice(1);
                      return <option key={item} value={name} >{name}</option>
                    })}
                  </Form.Control>

                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Social Post Link <span style={{ color: "red" }}>*</span></Form.Label>
                  <Form.Control required type='text' placeholder="Post Link" onChange={(e) => setModalEventData({ ...modalEventData, linkToPost: e.target.value })} value={modalEventData?.linkToPost} />
                </Form.Group>
              </Col>
            </Row>
            <div>
              <Button disabled={disableButtons} variant="outline-secondary" style={{ marginRight: "5px" }} onClick={closeAction}>
                Cancel
              </Button>
              <Button disabled={disableButtons} variant="outline-primary" type='submit'>
                Save
              </Button>
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default SocialContentUpdateModal;
