import React, { Component } from 'react'
import readXlsxFile, {readSheetNames} from 'read-excel-file'
import axios from 'axios'

import { host, url } from './../../config/common'
import TokenExpireHandler from './tokenExpireHandler'

const buttonRef = React.createRef()

export default class FileUploader extends Component {
  
  constructor(props){
    super(props)
    this.state = {
    }
    this.handleChange = this.handleChange.bind(this)
  }
  handleChange(e) {
    const files = e.target.files;
    this.props.onHide()
    if (!files) {
      this.props.alert.show("Please provide the file.", {type: "warning"})
      return 
    }
    this.props.alert.show("Airport data will be uploaded in the background. You will be notified once done.", {type: "success"})
    readSheetNames(files[0]).then((allSheets) => {
      let sheetUpload = allSheets.map((name, index) => 
        new Promise(resolve => {
          let tempDataHandle = null
          readXlsxFile(files[0], {sheet: name}).then((rows) => {
            if (rows.length > 285 ) {
              tempDataHandle = rows.splice(0, 285)
              debugger
            }

            // nomal data if not exxeded
            let sheetStateUpdation = rows.map(item => {
              item[1] = name
            })
            Promise.all(sheetStateUpdation).then(() => {
              this.saveAirportData(rows)
              resolve()
            })
            // nomal

            if (tempDataHandle) {
              let bufferSheetStateUpdation = tempDataHandle.map(item => {
                item[1] = name
              })
              Promise.all(bufferSheetStateUpdation).then(() => {
                this.saveAirportData(tempDataHandle)
                resolve()
              })
            }
          })
        })
      )
      Promise.all(sheetUpload).then(() => {
        this.props.alert.show("Airport data upload successfull. Please reload for the changes.", {type: "success"})
      })
    })
  }

  saveAirportData = async (data) => {
    try {
      let allAirportData = await axios({  
        method: 'patch',
        url: `${host}${url.updateAirportDataFromCSV}`,
        data: {csvData: data}
      });
      if (allAirportData) {
      }
    } catch (e) {
      console.log(e)
      TokenExpireHandler(this.props.navigate, e)
    }
  }

  render() {
    return (
      <>
        <form className="form-inline">
          <div className="form-group">
            <label htmlFor="file">Upload Excel File.</label>
            <input
              type="file"
              className="form-control"
              id="file"
              accept={this.state.SheetJSFT}
              onChange={this.handleChange}
            />
          </div>
        </form>

      </>
    )
  }
}