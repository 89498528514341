import React, { useState } from 'react'
import { Badge, Button, Col, Form, Offcanvas, Row } from 'react-bootstrap'
import VisibilityIcon from '@mui/icons-material/Visibility';

import ClaimStatusModal from './claimStatausModal';

export default function ClaimInfo({claimData, reRenderFn, ...props}) {
  const [show, setShow] = useState(false);
  const [selectedFbo, setSelectedFbo] = useState({})
  const [showAproAndRejModal, setShowAproAndRejModal] = useState(false);
  const [approve, setApprove] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  console.log({claimData: claimData.createdBy});
  return (
    <div>
      <ClaimStatusModal 
        show={showAproAndRejModal} 
        showModal={()=> setShowAproAndRejModal(true)} 
        onHide={() => setShowAproAndRejModal(false)}
        claimData={claimData}
        selectedFbo={selectedFbo}
        approve={approve}
        reRenderFn={reRenderFn}
        hideOffcanvas={() => setShow(false)}
      />
      <Button variant="outline-primary" onClick={handleShow}>
        <VisibilityIcon />
      </Button>
      <Offcanvas style={{width: "50%"}} show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Claim detail for Id: "{claimData._id}"</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            <Col md="4">
              <Form.Group className="mb-3">
                <Form.Label>User name</Form.Label>
                <Form.Control placeholder={claimData.userName} disabled />
              </Form.Group>
            </Col>
            <Col md="4">
              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control placeholder={claimData.email} disabled />
              </Form.Group>
            </Col>
            <Col md="4">
              <Form.Group className="mb-3">
                <Form.Label>Contact Number</Form.Label>
                <Form.Control placeholder={claimData.contactNumber} disabled />
              </Form.Group>
            </Col>
          </Row>
          <h3>FBO Details</h3>
          {
            claimData.createdBy ?
            <Row>
              <Col md="4">
                <Form.Group className="mb-3">
                  <Form.Label>FBO Name</Form.Label>
                  <Form.Control placeholder={claimData.name} disabled />
                </Form.Group>
              </Col>
              <Col md="4">
                <Form.Group className="mb-3">
                  <Form.Label>Airport ID</Form.Label>
                  <Form.Control placeholder={claimData.airportID} disabled />
                </Form.Group>
              </Col>
              <Col md="4">
                <Form.Group className="mb-3">
                  <Form.Label>FBO Contact Number</Form.Label>
                  <Form.Control placeholder={claimData.contactNumber} disabled />
                </Form.Group>
              </Col>
              <Col md="4">
                <Form.Group className="mb-3">
                  <Form.Label>FBO ASRI</Form.Label>
                  <Form.Control placeholder={claimData.ASRI} disabled />
                </Form.Group>
              </Col>
              <Col md="4">
                <Form.Group className="mb-3">
                  <Form.Label>Facility Type</Form.Label>
                  <Form.Control placeholder={claimData.facilityType} disabled />
                </Form.Group>
              </Col>
              <Col md="4">
                <Form.Group className="mb-3">
                  <Form.Label>City</Form.Label>
                  <Form.Control placeholder={claimData.city} disabled />
                </Form.Group>
              </Col>
              <Col md="4">
                <Form.Group className="mb-3">
                  <Form.Label>State</Form.Label>
                  <Form.Control placeholder={claimData.state} disabled />
                </Form.Group>
              </Col>
              <Col md="4">
                <Form.Group className="mb-3">
                  <Form.Label>Facility Name</Form.Label>
                  <Form.Control placeholder={claimData.facilityName} disabled />
                </Form.Group>
              </Col>
              <div style={{marginTop: "14px"}}>
                Status: {
                  claimData.alloted ? 
                  <Badge>Already alloted to another user.</Badge>: 
                  claimData.approved ?
                  <Badge bg="success">Approved</Badge> :
                  claimData.rejected ?
                    <Badge bg="danger">Rejected</Badge>:
                    <Badge>Pending</Badge>
                }{
                  claimData.approved ?
                  <></>:
                  claimData.rejected ?
                  <></>:
                  <>
                    <Button variant='outline-success' onClick={() => {setShowAproAndRejModal(true); setApprove(true)}} style={{margin: "0px 10px"}}>Approve</Button>
                    <Button variant='outline-danger' onClick={() => {setShowAproAndRejModal(true); setApprove(false)}}>Reject</Button>
                  </>
                }     
              </div>
            </Row> : <></>
          }
          {
            claimData?.fbo?.map(item =>
              <Row key={item._id}>
                <Col md="3">
                  <Form.Group className="mb-3">
                    <Form.Label>FBO ID</Form.Label>
                    <Form.Control placeholder={item.fboId} disabled />
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group className="mb-3">
                    <Form.Label>FBO Name</Form.Label>
                    <Form.Control placeholder={item.fboName} disabled />
                  </Form.Group>
                </Col>
                <Col md="5">
                  <div style={{marginTop: "14px"}}>
                    Status: {
                      item.alloted ? 
                      <Badge>Already alloted to another user.</Badge> : 
                      item.approved ?
                      <Badge bg="success">Approved</Badge> :
                      item.rejected ?
                        <Badge bg="danger">Rejected</Badge>:
                        <Badge>Pending</Badge>
                    }{
                      item.alloted ? 
                        <></>
                      : 
                        item.approved ?
                        <></>:
                        item.rejected ?
                        <></>:
                        <>
                          <Button variant='outline-success' onClick={() => {setShowAproAndRejModal(true); setApprove(true); setSelectedFbo(item)}} style={{margin: "0px 10px"}}>Approve</Button>
                          <Button variant='outline-danger' onClick={() => {setShowAproAndRejModal(true); setApprove(false); setSelectedFbo(item)}}>Reject</Button>
                        </>
                    }
                  </div>
                </Col>
              </Row>
            )
          }
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  )
}