import React, {useEffect} from 'react'
import {Form, Button, Card} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import axios from 'axios'
import { useAlert } from 'react-alert'

import image from './../asset/Map.png'
import logo from './../asset/bootsplash_logo_original.png'
import {host, url} from './../../config/common'
import TokenExpireHandler from '../utils/tokenExpireHandler'

export default function Login() {
  const alert = useAlert()
  let navigate = useNavigate()
  let css = {
    cardCenter : {
      width: '28rem',
      margin: '0',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    }
  }
  useEffect(() => {
    if (localStorage.getItem('user')) {
      navigate("/home")
    }
  });
  const Submit = async (e) => {
    e.preventDefault();
    try {
      let user = await axios({  
        method: 'post',  
        url: `${host}${url.login}`,
        data: {  
          email: e.target[0].value,
          password: e.target[1].value,
        }  
      })
      if (user.status === 200) {
        if(!user.data.isAdmin){
          return alert.show("Not an admin account.", {type: 'error'})
        }
        localStorage.setItem("user", JSON.stringify(user.data))
        return navigate("/home")
      }
    } catch (err) {
      TokenExpireHandler(navigate, err)
      console.log('error::', err)
      alert.show(err.response.data.message, {type: 'error'})
    }
  }
  return (
    <>
      <div style={{backgroundImage: `linear-gradient(rgba(255,255,255,0.3), rgba(255,255,255,.3)),url(${image})`, height: '100vh', width: '100%'}}>
        <Card  style={css.cardCenter}>
          <Card.Body>
            <Card.Img variant="top" src={logo} style={{width: '100px', textAlign: 'center'}} />
            <Form onSubmit={Submit} style={{textAlign: 'left'}}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control type="email" placeholder="Enter email" />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" placeholder="Password" />
              </Form.Group>
              <Button variant="primary" type="submit">
                Login
              </Button>
              {/* <Form.Group>
                <Form.Label>Don't have an acoount! <Link to="/signup"> Click to Signup. </Link></Form.Label>
              </Form.Group> */}
            </Form>
          </Card.Body>
        </Card>
      </div>
    </>
  )
}
