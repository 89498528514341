import { createSlice } from '@reduxjs/toolkit';

const airportSlice = createSlice({
  name: 'airports',
  initialState: {
    data: [],
  },
  reducers: {
    fetchAirports(state, action) {
      state.data = action.payload
    }
  },
});

export const { fetchAirports } = airportSlice.actions;
export const airportReducer = airportSlice.reducer;
