import React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { ThemeProvider, createTheme } from "@mui/material";

const CustomPagination = ({ props }) => {
  const { currentPage, totalPages, totalRecords, handlePageChange } = props?.paginationProps;
  // console.log(props, "this is the pagination props", currentPage, totalPages, totalRecords);

  const PaginationTheme = createTheme({
    components: {
      MuiPaginationItem: {
        styleOverrides: {
          root: {
            "&.MuiPaginationItem-root": {
              fontFamily: "Work Sans",
            },
            "&.MuiPaginationItem-previousNext": {
              border: "1px solid #E6E6E6",
              color: "#000000",
            },
            "&.Mui-selected": {
              backgroundColor: "#0518A2",
              color: "white",
            },
            "&.Mui-selected:hover": {
              backgroundColor: "#0518A2",
            },
          },
        },
      },
    },
  });

  return (
    <div className='flex div-end' style={{ paddingTop: "10px", display: "flex", justifyContent: "center" }}>
      <ThemeProvider theme={PaginationTheme}>
        <Stack>
          <Pagination
            count={totalPages}
            page={Number(currentPage)}
            onChange={(e, page) => {
              handlePageChange(e, page);
            }}
            shape='rounded'
          />
        </Stack>
      </ThemeProvider>
    </div>
  );
};

export default CustomPagination;
