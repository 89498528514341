import React, { useEffect, useState } from "react";
import {Offcanvas, Button, Form, Row, Col, Image} from "react-bootstrap"
import StarRatings from 'react-star-ratings';
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import axios from "axios";
import { useAlert } from 'react-alert'

import { host, url } from "../../config/common";
import avatar from './../asset/avatar.webp'
import Loader from "./loader";
import AirportDeletionModal from "./deletionModal";
import TokenExpireHandler from "./tokenExpireHandler";
import ImagePreview from "./imagePreview";

export default function UserModPopup({ reviewDataProp, reRenderFn, navigate, ...props}) {
  const customClass = {
    OffcanvasCustomWidth: {
      width: '50%'
    }
  }
  const [show, setShow] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [deleteModalShow, setdeleteModalShow] = useState(false);
  const [reviewData, reviewDataOperation] = useState(reviewDataProp);
  const [showImagePreview, showImagePreviewOperation] = useState(false);
  const [imageSrc, imageSrcOperation] = useState(null);
  const alert = useAlert()

  if (!reviewDataProp) {
    reviewDataProp = {
      _id: "",
      airportID: "",
      review: "",
      userName: "",
      stars: 0,
      userProfilePicture: "",
      images: []
    }
  }

  useEffect(() => {
    reviewDataOperation({ ...reviewDataProp })
  }, [reviewDataProp])

  const handleShow = () => setShow(true);

  let updateReview = async () => {
    try {
      setShowLoader(true)
      let updatedUser = await axios({  
        method: 'patch',
        headers: {'x-access-token': JSON.parse(localStorage.getItem('user'))?.token ? JSON.parse(localStorage.getItem('user')).token : ""},
        url: `${host}${url.updateReview}`,
        data: reviewData  
      });
      if (updatedUser) {
        handleClose()
        setShowLoader(false)
        reRenderFn()
        reviewDataOperation({ ...updatedUser.data.data })
        alert.show("Review updated.", {type: 'success'})
      }
    } catch (e) {
      console.log(e)
      setShowLoader(false)
      alert.show("Someting went wrong!", {type: 'warning'})
      TokenExpireHandler(navigate, e)
    }
  } 
  const handleClose = () => {
    setShow(false)
    reviewDataOperation({ ...reviewDataProp })
  };

  const handleImagePreviewClose = () => {
    showImagePreviewOperation(false)
    imageSrcOperation(null)
  };
  const displayImagePreview = (src) => {
    showImagePreviewOperation(true)
    imageSrcOperation(src)
  };

  const deleteReview = async () => {
    try {
      setShowLoader(true)
      let updatedUser = await axios({  
        method: 'delete',
        headers: {'x-access-token': JSON.parse(localStorage.getItem('user'))?.token ? JSON.parse(localStorage.getItem('user')).token : ""},
        url: `${host}${url.deleteReview}/${reviewData._id}`
      });
      if (updatedUser) {
        setShowLoader(false)
        setdeleteModalShow(false)
        alert.show("Review deleted.", {type: 'success'})
        reRenderFn()
        handleClose()
      }
    } catch (e) {
      console.log(e)
      setShowLoader(false)
      setdeleteModalShow(false)
      alert.show(e.message, {type: 'warning'})
      TokenExpireHandler(navigate, e)
    }
  }

  return (
    <>
      <AirportDeletionModal
        deletionfunction={() => {deleteReview()}}
        user={reviewData}
        show={deleteModalShow}
        onHide={() => {setdeleteModalShow(false)}}
      />
      <Loader show={showLoader}/>
      <ImagePreview show={showImagePreview} src={imageSrc} handleImagePreviewClose={() => handleImagePreviewClose()}/>
      <Button variant="outline-primary" className="me-2" onClick={handleShow}>
        <EditIcon />
      </Button>
      <Offcanvas
        style={customClass.OffcanvasCustomWidth} 
        backdrop="static"
        show={show}
        onHide={handleClose}
        {...props}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Edit Review</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form onSubmit={updateReview}>
            <Row>
              <Col lg="5">
                <Image rounded={'true'} src={reviewData.userProfilePicture ? reviewData.userProfilePicture : avatar} width={"100%"}/>
              </Col>
              <Col >  
                <Form.Group className="mb-2">
                  <Form.Label>{reviewData.airportID? "Airport ID" : "FBO Name"}</Form.Label>
                  <Form.Control 
                    readOnly
                    disabled
                    value={reviewData.airportID? reviewData.airportID : reviewData.name}
                    // onChange={e => {
                    //   reviewDataOperation({ ...reviewData, airportID: e.target.value });
                    // }}
                  />
                </Form.Group>
                <Form.Group className="mb-2">
                  <Form.Label>User Name</Form.Label>
                  <Form.Control 
                    disabled
                    readOnly
                    value={reviewData.userName}
                    // onChange={e => {
                    //   reviewDataOperation({ ...reviewData, userName: e.target.value });
                    // }}
                  />
                </Form.Group>
                <Form.Group className="mb-2">
                  <Form.Label>Review</Form.Label>
                  <Form.Control 
                    as="textarea"
                    rows={4}
                    value={reviewData.review}
                    onChange={e => {
                      reviewDataOperation({ ...reviewData, review: e.target.value });
                    }}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Ratings</Form.Label>
                  <div>
                    <StarRatings
                      rating={reviewData.stars}
                      starRatedColor='rgb(13,110,253,1)'
                      starDimension="15px"
                      changeRating={(newRating) => {
                        reviewDataOperation({ ...reviewData, stars: newRating });
                      }}
                      numberOfStars={5}
                      name='rating'
                    />
                  </div>
                </Form.Group>
              </Col>
            </Row>
          </Form>
          <Row>
            <Col style={reviewData.images.length ? {height: "215px", overflow:"auto"} : {} }>
              {reviewData.images.map((item, index) => 
                <span key={index} style={{position: "relative", cursor: "pointer"}}>
                  <Image src={item} width="200px" height="200px" className="m-2" onClick={() => displayImagePreview(item)}></Image>
                  <span style={{
                    position: "absolute",
                    top: "-89px",
                    left: "184px",
                    color: "#ff0000",
                    background: "#ffffffb8",
                    borderRadius: "0px 0px 0px 10px"
                  }} onClick={() => {reviewData.images.splice(index, 1); reviewDataOperation({...reviewData, images: reviewData.images})}}>
                    <HighlightOffIcon />
                  </span>
                </span>)
              }
            </Col>
          </Row>
          <Button className="m-2" variant='outline-success' onClick={() =>updateReview()}>Save</Button>
          <Button className="m-2" variant='outline-danger' onClick={() => deleteReview()}>Delete Review</Button>
          <Button className="m-2" onClick={() =>handleClose()}>Cancel</Button>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}