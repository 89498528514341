import React, { useState } from 'react';
import DateRange from '@mui/icons-material/DateRange';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import LocalAirportIcon from '@mui/icons-material/LocalAirport';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import PersonIcon from '@mui/icons-material/Person';
import LanguageIcon from '@mui/icons-material/Language';
import SwipeRightIcon from '@mui/icons-material/SwipeRight';
import { Button, Card, Col, Modal, Row } from 'react-bootstrap';
import moment from 'moment';
import axios from 'axios';
import { host, url } from '../../config/common';
import OnsiteFoodUpdateModal from './onsiteFoodUpdateModal';
import { useAlert } from 'react-alert';
import { Approval } from '@mui/icons-material';

const OnsiteFoodCard = ({ foodData, refreshData, ...props }) => {
  const [showEdit, setShowEdit] = useState(false);
  const [showDeleteModal, setshowDeleteModal] = useState(false);
  const [showAcceptModal, setShowAcceptModal] = useState(false);

  const alert = useAlert()

  const handleEdit = () => {
    setShowEdit(true)
  }

  const handleClose = () => {
    setShowEdit(false)
  }
  const acceptRejectRequest = async (id, status) => {
    try {
      let foodRequest = await axios({
        method: 'patch',
        headers: { 'x-access-token': JSON.parse(localStorage.getItem('user'))?.token ? JSON.parse(localStorage.getItem('user')).token : "" },
        url: `${host}${url.changeStatusShareAnUpdate}`,
        data: {
          _id: id,
          status: status
        }
      });
      if (foodRequest) {
        alert.show(`Request ${status == 1 ? "accepted" : "rejected"}`, {type: 'success'})
        setshowDeleteModal(false);
        setShowAcceptModal(false);
        refreshData()
      }
    } catch (error) {
      console.log({ error });
    }
  }

  const handleWebsiteClick = (url) => {
    if (url.startsWith('http://') || url.startsWith('https://')) {
      window.open(url, '_blank');
    } else {
      window.open(`http://${url}`, '_blank');
    }
  }

  return (
    <div>
      <OnsiteFoodUpdateModal show={showEdit} handleClose={handleClose} foodData={foodData} refreshData={() => refreshData()}/>
      <Modal show={showDeleteModal} onHide={() => setshowDeleteModal(false)} backdrop="static">
        <Modal.Body>Are you sure you want to reject the onsite food request?</Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => setshowDeleteModal(false)}>
            No
          </Button>
          <Button variant="outline-danger" onClick={() => acceptRejectRequest(foodData._id, 2)}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Card style={{ flexDirection: "initial", marginBottom: "10px" }}>
        <Card.Body style={{ textAlign: "initial" }}>
          <p style={{ fontWeight: "300" }}><LocalAirportIcon fontSize='10px' />  <b>Airport ID :</b> {foodData?.airportID}</p>
          <p style={{ fontWeight: "300" }}><Approval fontSize='10px' />  <b>FBO :</b> {foodData?.fboName}</p>
          <p style={{ fontWeight: "300" }}><RestaurantIcon fontSize='10px' />  <b>Restaurant Name :</b> {foodData?.restaurantName}</p>
          <p style={{ fontWeight: "300" }}><PhoneAndroidIcon fontSize='10px' />  <b>Restaurant Phone :</b> {foodData?.restaurantPhone}</p>
          <p style={{ fontWeight: "300" }}><LanguageIcon fontSize='10px' />  <b>Restaurant Website :</b> {foodData?.restaurantWebsite && <span style={{color:"#0d6efd", cursor: "pointer"}} onClick={() => handleWebsiteClick(foodData?.restaurantWebsite)}>{foodData?.restaurantWebsite}</span>}</p>
          <p style={{ fontWeight: "300" }}><HourglassBottomIcon fontSize='10px' />  <b>Hours of Operation :</b> {foodData?.restaurantOperationHours}</p>
          <p style={{ fontWeight: "300" }}><ContactMailIcon fontSize='10px' />  <b>Address :</b> {foodData?.restaurantAddress}</p>
          <p style={{ fontWeight: "300" }}><PersonIcon fontSize='10px' />  <b>Requested By :</b> {foodData?.requestedBy}</p>
          <p style={{ fontWeight: "300" }}><DateRange fontSize='10px' />  <b>Requested Date :</b> {moment(foodData?.createdAt).format("Do MMM YYYY")}</p>
          <Row>
            <Col>
              <div style={{ display: "flex", justifyContent: "end" }}>
                {foodData?.status === 0 ?
                  <div>
                    <span style={{ fontWeight: "300", color: "#006ba3", cursor: "pointer", marginRight: "10px" }} onClick={() => handleEdit()}><SwipeRightIcon fontSize='9px' /> Accept</span>
                    <span style={{ fontWeight: "300", color: "red", cursor: "pointer" }} onClick={() => setshowDeleteModal(true)}><DeleteOutlineIcon fontSize='9px' /> Reject</span>
                  </div>
                  :
                  foodData?.status === 1 ? <span style={{color: "green"}}>Accepted</span> : <span style={{color: "red"}}>Rejected</span>
                }

              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
}

export default OnsiteFoodCard;
