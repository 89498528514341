import React, { useEffect, useState } from 'react';
import { Button, Col, Form, FormControl, InputGroup, Row } from 'react-bootstrap';
import axios from 'axios';
import { host, url } from '../../../config/common';
import Loader from '../../utils/loader';
import CustomPagination from './pagination/customPagination';
import UnlistedEventCard from '../../utils/unlistedEventCard';
import { useNavigate } from 'react-router-dom';

const UnlistedServices = () => {
    const [searchInput, setSearchInput] = useState("");
    const [airportList, setAirportList] = useState([]);
    const [isAirportList, setIsAirportList] = useState(false);
    const [isEventList, setIsEventList] = useState(false);
    const [unlistedServiceData, setUnlistedServiceData] = useState({
        list: [],
        loading: false,
        currentPage: "1",
        totalPages: 1,
        totalRecords: 1,
    });
    const [serviceParams, setServiceParams] = useState({
        page: 1,
        limit: 21,
    });
    const [showloader, setShowloader] = useState(false);
    let navigate = useNavigate();

    const fetchUnlistedServices = async () => {
        try {
            setShowloader(true)
            let fetchedEventData = await axios({
                method: 'get',
                headers: { 'x-access-token': JSON.parse(localStorage.getItem('user'))?.token ? JSON.parse(localStorage.getItem('user')).token : "" },
                url: `${host}${url.getShareAnUpdate}?search=${searchInput}&page=${serviceParams.page}&limit=${serviceParams.limit}&eventName=unlisted_service`
            });
            if (fetchedEventData?.status == 401) {
                localStorage.removeItem("user")
                return navigate("/")
            }
            if (fetchedEventData) {
                setShowloader(false);
                setIsEventList(true);
                setUnlistedServiceData((v) => ({
                    ...v,
                    list: fetchedEventData?.data?.data?.records,
                    loading: false,
                    currentPage: fetchedEventData?.data?.data?.currentPage,
                    totalPages: fetchedEventData?.data?.data?.totalPages,
                    totalRecords: fetchedEventData?.data?.data?.totalRecords,
                }));
            }
        } catch (error) {
            console.log({ error });
            if (error?.response?.status == 401) {
                localStorage.removeItem("user")
                return navigate("/")
            }
        }
    }

    const fetchAirportData = async () => {
        // setShowloader(true);
        let allAirportData = await axios({
            method: 'get',
            headers: { 'x-access-token': JSON.parse(localStorage.getItem('user'))?.token ? JSON.parse(localStorage.getItem('user')).token : "" },
            url: `${host}${url.getAirportNameList}?isBeta=true`,
            params: { fromAdmin: true, isBeta: true }
        });
        if (allAirportData.status == 401) {
            localStorage.removeItem("user")
            return this.props.navigate("/")
        }
        if (allAirportData) {
            // setShowloader(false);
            setIsAirportList(true);
            setAirportList(allAirportData.data);
        }
    }

    const searchServices = async (e) => {
        try {
            setServiceParams((v) => ({ ...v, page: 1 }));
            fetchUnlistedServices();
        } catch (error) {
            console.log({ error });
        }
    }

    const handlePageChange = (e, page) => {
        setServiceParams((v) => ({ ...v, page: page }));
    };

    const paginationProps = {
        currentPage: unlistedServiceData?.currentPage,
        totalPages: unlistedServiceData?.totalPages,
        totalRecords: unlistedServiceData?.totalRecords,
        handlePageChange,
    };

    useEffect(() => {
        fetchUnlistedServices();
    }, [serviceParams]);

    // useEffect(() => {
    //     fetchAirportData();
    // }, []);

    // useEffect(() => {
    //     setShowloader(true);
    //     if (isAirportList && isEventList) {
    //         setShowloader(false); 
    //     }
    // }, [isAirportList, isEventList]);

    return (
        <div style={{ padding: "0px 20px" }}>
            <Row>
                <Col>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <h3>Unlisted Service Requests</h3>
                    </div>
                </Col>
            </Row>
            <Row>
                <div>
                    <Row>
                        <Col xs="7">
                            <Form onSubmit={(e) => { e.preventDefault(); searchServices() }}>
                                <InputGroup style={{ width: "500px", float: "left", marginBottom: "1%" }}>
                                    <FormControl
                                        placeholder="Search by service name"
                                        onChange={(e) => setSearchInput(e.target.value)}
                                        value={searchInput}
                                    />
                                    <Button variant="outline-primary" type='submit'>
                                        Search
                                    </Button>
                                </InputGroup>
                            </Form>
                        </Col>
                    </Row>
                    <Loader show={showloader} />
                    <Row style={{ height: "82vh", overflow: "scroll " }}>
                        <Col>
                            {unlistedServiceData?.list?.length > 0
                                ?
                                <>
                                    <Row style={{ marginTop: "20px" }}>
                                        {
                                            unlistedServiceData?.list?.map((item) =>
                                                <Col sm="4" key={item._id}>
                                                    <UnlistedEventCard unlistedServiceData={item} refreshData={() => fetchUnlistedServices()} airportList={airportList} />
                                                </Col>
                                            )
                                        }
                                    </Row>
                                    <CustomPagination props={{ paginationProps }} />
                                </>
                                :
                                <Row style={{ marginTop: "20px" }}>No Records Found</Row>
                            }

                        </Col>
                    </Row>
                </div>
            </Row>
        </div>
    );
}

export default UnlistedServices;
