import { configureStore } from '@reduxjs/toolkit';
import { airportReducer, fetchAirports } from './airportSlice';

const store = configureStore({
  reducer: {
    airports: airportReducer,
  },
});

export { store, fetchAirports };
