import React, {useState} from 'react'
import {Navbar, Container, NavDropdown, Nav, Button} from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import SettingsIcon from '@mui/icons-material/Settings';

import logo from './asset/bootsplash_logo_original.png'
import ProfileSettingsModal from './utils/profileSettingsModal'
import image from './asset/Map1.png'

export default function Header() {
  const [show, setShow] = useState(false);
  let navigate = useNavigate()
  const onBrandClick = () => {
    if (localStorage.getItem('user')) {
      navigate('/home/')
      return
    }
    navigate('/')
  }
  const logout = () => {
    localStorage.removeItem('user')
    navigate('/')
  }
  const userProfile = () => {
    if (localStorage.getItem('user')) {
      return (
        <>
          <Navbar.Text>
            {JSON.parse(localStorage.getItem('user')).firstName} {JSON.parse(localStorage.getItem('user')).lastName}
          </Navbar.Text>
          <Nav>
            <NavDropdown
              id="nav-dropdown-dark-example"
              title= {settingIcon}
              menuVariant="dark"
            >
              <NavDropdown.Item onClick={() => setShow(true)}>Profile</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={() => {logout()}}>Logout</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </>
      )
    }
    return null
  }
  const settingIcon = (<SettingsIcon></SettingsIcon>)
  return (
    <Navbar bg="white" sticky="top" variant="light">
      <Container>
        <ProfileSettingsModal
          show={show}
          onHide={() => setShow(false)}
          userProfile={JSON.parse(localStorage.getItem('user'))}
        />
          <span>
            <Navbar.Brand to="/home" onClick={() => onBrandClick()} style={{cursor: "pointer"}}>
                <img
                  alt="logo fbo"
                  src={logo}
                  width="40"
                  height="40"
                  className="d-inline-block align-top"
                  />{' '}
              <span style={{color: "#2283b5"}}>
                FBOProps
              </span>
            </Navbar.Brand>
          </span>
        <div>
          <Navbar.Collapse className="justify-content-end">
            {userProfile()}
          </Navbar.Collapse>
        </div>
      </Container>
    </Navbar>
  )
}
