import React from 'react'
import {Modal, Button} from 'react-bootstrap'

import FileUploader from './fileUploader'
import AirportExcelDownloader from './airportExcelDownloader'
import { useAlert } from 'react-alert'

export default function AirportDataModificationModal(props) {
  const alert = useAlert()
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <FileUploader alert={alert} onHide={props.onHide} />
        <hr />
        <AirportExcelDownloader hideModal={() => props.onHide()} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant='danger' onClick={props.onHide}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  );
}