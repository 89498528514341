import React, { useState } from 'react';
import { Row, Tab, Tabs } from 'react-bootstrap';
import OnsiteFood from './onsiteFood';
import CoolEvents from './coolEvents';
import UnlistedServices from './unlistedServices';
import FboUpdates from './fboUpdates';
import SocialContent from './socialContent';

const ShareAndUpdateRequests = ({ navigate }) => {
    const [key, setKey] = useState('onsiteFood');

    return (
        <div className='share-request' style={{ padding: "0px 20px" }}>
            <Row>
                <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3 req-tab"
                >
                    <Tab eventKey="onsiteFood" title="Onsite Food">
                        {key === "onsiteFood" && <OnsiteFood />}
                    </Tab>
                    <Tab eventKey="events" title="Cool Events">
                        {key === "events" && <CoolEvents />}
                    </Tab>
                    <Tab eventKey="services" title="Unlisted Service">
                        {key === "services" && <UnlistedServices />}
                    </Tab>
                    <Tab eventKey="fboUpdates" title="FBO Updates">
                        {key === "fboUpdates" && <FboUpdates />}
                    </Tab>
                    <Tab eventKey="socialMedia" title="Social Media">
                        {key === "socialMedia" && <SocialContent />}
                    </Tab>
                </Tabs>
            </Row>
        </div>
    );
}

export default ShareAndUpdateRequests;
